import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CookingClassList() {
    const [cookingClasses, setCookingClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null); // For editing
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [volunteers, setVolunteers] = useState([]); // Store the list of volunteers

    useEffect(() => {
        const fetchCookingClasses = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/cooking-classes');
                if (!response.ok) {
                    const text = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                }
                const data = await response.json();
                setCookingClasses(data);
                console.log('Cooking classes fetched successfully:', data);
            } catch (error) {
                console.error('Error fetching cooking classes:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCookingClasses();
    }, []);

    const fetchVolunteers = async () => {
        try {
            const response = await fetch('https://nomorewaste.fun/api/volunteers');
            if (!response.ok) {
                throw new Error('Failed to fetch volunteers');
            }
            const data = await response.json();
            setVolunteers(data);
        } catch (error) {
            console.error('Error fetching volunteers:', error);
        }
    };

    const handleEdit = (cookingClass) => {
        setSelectedClass(cookingClass);
        fetchVolunteers(); // Fetch the list of volunteers when editing starts
        setShowEditModal(true);
    };

    const handleDelete = (cookingClass) => {
        setSelectedClass(cookingClass);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (!selectedClass) return;
        try {
            const response = await fetch(`https://nomorewaste.fun/api/delete-cooking-class/${selectedClass.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setCookingClasses(cookingClasses.filter((cls) => cls.id !== selectedClass.id));
                setShowDeleteModal(false);
            } else {
                const text = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
            }
        } catch (error) {
            console.error('Error deleting cooking class:', error);
        }
    };

    const handleSaveChanges = async () => {
        if (!selectedClass) return;

        try {
            const response = await fetch(`https://nomorewaste.fun/api/update-cooking-class/${selectedClass.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    title: selectedClass.title,
                    description: selectedClass.description,
                    duration: selectedClass.duration,
                    startTime: selectedClass.startTime,
                    maxParticipants: selectedClass.maxParticipants,
                    volunteerId: selectedClass.volunteer?.id,  // Correctly passing the volunteer ID
                    isValidated: selectedClass.isValidated,  // Include isValidated in the data
                }),
            });

            if (response.ok) {
                const updatedClass = await response.json();
                setCookingClasses((prevClasses) =>
                    prevClasses.map((cls) =>
                        cls.id === updatedClass.id ? updatedClass : cls
                    )
                );
                setShowEditModal(false);
            } else {
                const text = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
            }
        } catch (error) {
            console.error('Error saving cooking class:', error);
        }
    };

    if (loading) {
        return <p className="text-center text-gray-500">Loading cooking classes...</p>;
    }

    if (error) {
        return <p className="text-center text-red-500">Error loading cooking classes: {error}</p>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Cooking Classes</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {cookingClasses.length > 0 ? (
                    cookingClasses.map((cookingClass) => (
                        <div key={cookingClass.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
                         <img src="/images/3.jpg" alt="Cooking Class" className="w-full h-48 object-cover" />
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-2">{cookingClass.title}</h2>
                                <p className="text-gray-700 mb-4">{cookingClass.description}</p>
                                <p className="text-sm text-gray-600">Duration: {cookingClass.duration} minutes</p>
                                <p className="text-sm text-gray-600">Start Time: {new Date(cookingClass.startTime).toLocaleString()}</p>
                                <p className="text-sm text-gray-600">Bénévole: {cookingClass.volunteer?.name}</p>
                                <p className="text-sm text-gray-600 font-bold mt-2">Available Slots: {cookingClass.availableSlots}</p>
                                <p className="text-sm text-gray-600 font-bold mt-2">Max Participants: {cookingClass.maxParticipants}</p>
                                <p className="text-sm text-gray-600 font-bold mt-2">Bookings: {cookingClass.bookingCount}</p>
                                <p className="text-sm text-gray-600 font-bold mt-2"> Status: {cookingClass.isValidated ? 'Validé' : 'Non Validé'}</p>
                                <button 
                                    onClick={() => handleEdit(cookingClass)}
                                    className="block w-full py-2 mt-2 text-center rounded bg-green-500 text-white font-semibold hover:bg-green-600"
                                >
                                    Modifier
                                </button>
                                <button 
                                    onClick={() => handleDelete(cookingClass)}
                                    className="block w-full py-2 mt-2 text-center rounded bg-red-500 text-white font-semibold hover:bg-red-600"
                                >
                                    Supprimer
                                </button>
                                <button 
                                    
                                    className="block w-full py-2 mt-2 text-center rounded bg-yellow-500 text-white font-semibold hover:bg-red-600"
                                >
                                    <Link to={`/admin/cooking-class/${cookingClass.id}/bookings`}>
                                        Voir qui a réservé
                                    </Link>

                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="col-span-full text-center text-gray-500">No cooking classes available at the moment.</p>
                )}
            </div>
            <button className="block w-1/2  mt-10 py-2 text-center rounded bg-blue-500 text-white font-semibold hover:bg-blue-600">
                <Link to={'/admin/create-cooking-class'} className="block w-full py-2 text-center bg-blue-500 text-white font-semibold hover:bg-blue-600">
                    Créer un cours de cuisine
                </Link>
            </button>

            {/* Edit Modal */}
            {showEditModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Modifier le cours</h2>
                        <input
                            className="border p-2 w-full mb-4"
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={selectedClass?.title || ''}
                            onChange={(e) => setSelectedClass({ ...selectedClass, title: e.target.value })}
                        />
                        <input
                            className="border p-2 w-full mb-4"
                            type="text"
                            name="description"
                            placeholder="Description"
                            value={selectedClass?.description || ''}
                            onChange={(e) => setSelectedClass({ ...selectedClass, description: e.target.value })}
                        />
                        <input
                            className="border p-2 w-full mb-4"
                            type="number"
                            name="duration"
                            placeholder="Duration"
                            value={selectedClass?.duration || ''}
                            onChange={(e) => setSelectedClass({ ...selectedClass, duration: e.target.value })}
                        />
                        <input
                            className="border p-2 w-full mb-4"
                            type="datetime-local"
                            name="startTime"
                            placeholder="Start Time"
                            value={selectedClass?.startTime || ''}
                            onChange={(e) => setSelectedClass({ ...selectedClass, startTime: e.target.value })}
                        />
                        <input
                            className="border p-2 w-full mb-4"
                            type="number"
                            name="maxParticipants"
                            placeholder="Max Participants"
                            value={selectedClass?.maxParticipants || ''}
                            onChange={(e) => setSelectedClass({ ...selectedClass, maxParticipants: e.target.value })}
                        />
                        <select
                            className="border p-2 w-full mb-4"
                            name="volunteerId"
                            value={selectedClass?.volunteer?.id || ''}
                            onChange={(e) => setSelectedClass({ ...selectedClass, volunteer: { id: e.target.value } })}
                        >
                            <option value="">Select a Volunteer</option>
                            {volunteers.map((volunteer) => (
                                <option key={volunteer.id} value={volunteer.id}>
                                    {volunteer.name}
                                </option>
                            ))}
                        </select>
                        <div className="flex items-center mb-4">
                            <input
                                className="mr-2"
                                type="checkbox"
                                name="isValidated"
                                checked={selectedClass?.isValidated || false}
                                onChange={(e) => setSelectedClass({ ...selectedClass, isValidated: e.target.checked })}
                            />
                            <label htmlFor="isValidated" className="text-sm text-gray-700">Validé</label>
                        </div>
                        <button onClick={() => setShowEditModal(false)} className="px-4 py-2 bg-gray-500 text-white rounded">Annuler</button>
                        <button onClick={handleSaveChanges} className="ml-2 px-4 py-2 bg-green-500 text-white rounded">Enregistrer</button>
                    </div>
                </div>
            )}

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Supprimer le cours</h2>
                        <p>Êtes-vous sûr de vouloir supprimer ce cours ?</p>
                        <button onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-500 text-white rounded">Annuler</button>
                        <button onClick={confirmDelete} className="ml-2 px-4 py-2 bg-red-500 text-white rounded">Supprimer</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CookingClassList;
