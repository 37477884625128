import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

function CookingClassDetail() {
    const { id } = useParams();
    const [cookingClass, setCookingClass] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`https://nomorewaste.fun/api/cooking-class/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch');
                }
                return response.json();
            })
            .then(data => {
                setCookingClass(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!cookingClass) return <p>No data</p>;

    return (
        <div>
            <h1>{cookingClass.title}</h1>
            <p>{cookingClass.description}</p>
            <p>Available Slots: {cookingClass.availableSlots}</p>
            <Link to={`/booking/new/${cookingClass.id}`}>Book Now</Link>
        </div>
    );
}

export default CookingClassDetail;
