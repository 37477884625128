import React from "react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ModalstockCarousel from "../../admin/Stock/ModalStockCarousel";

function ConseilAntiGaspiList() {
    const [conseils, setConseils] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchConseils = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/list-conseils');
                if (!response.ok) {
                    const text = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                }
                const data = await response.json();
                setConseils(data);
                console.log('Conseils fetched successfully with async/await:', data);
            } catch (error) {
                console.error('Error fetching conseils with async/await:', error);
                setError(error.message);
            }
        };

        fetchConseils();
    }, []);

    return (
        <div className="container mx-auto p-6 bg-green-50 min-h-screen">
            <h1 className="text-3xl font-bold mb-8 text-green-700">Conseils Anti-Gaspillage</h1>
            {error ? (
                <p className="text-red-500 text-lg font-semibold">Erreur lors du chargement des conseils : {error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {conseils.map((conseil) => (
                        <div key={conseil.id} className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center">
                            {/* Exemple d'image, à remplacer par une image réelle en lien avec le conseil */}
                            <img src="/1.jpg" alt={conseil.titre} className="w-full h-32 object-cover rounded-t-lg mb-4" />
                            <h2 className="text-2xl font-semibold text-green-800 mb-2">{conseil.titre}</h2>
                            <p className="text-sm text-gray-500 mb-2">Auteur: {conseil.auteur}</p>
                            <p className="text-sm text-gray-500 mb-4">Date: {new Date(conseil.date).toLocaleDateString()}</p>
                            <p className="mt-2 text-gray-700 text-justify flex-grow">{conseil.texte}</p>
                        </div>
                    ))}
                </div>
            )}

            <ModalstockCarousel />
            <p className="text-center mt-8">
                stay tuned pour d'autres conseils anti-gaspillage !
            </p>
            
        </div>
       
    );
};

export default ConseilAntiGaspiList;
