import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

const Don = () => {
    const [donations, setDonations] = useState([]);
    const [error, setError] = useState(null);
    const { user } = useAuth(); // Récupère l'utilisateur authentifié
    console.log('User in Don:', user); // Affiche les informations de l'utilisateur

    useEffect(() => {
        if (user && user.id) {
            const fetchDonations = async () => {
                try {
                    const response = await fetch(`https://nomorewaste.fun/api/dons/user/${user.id}`);
                    if (!response.ok) {
                        throw new Error('Erreur lors du chargement des dons');
                    }
                    const data = await response.json();
                    setDonations(data);
                } catch (error) {
                    console.error('Error fetching donations:', error);
                    setError(error.message);
                }
            };
    
            fetchDonations();
        }
    }, [user]); // Assurez-vous que 'user' est prêt avant de lancer l'effet

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Mes Dons</h1>
            {error ? (
                <p className="text-red-500">Erreur lors du chargement des dons : {error}</p>
            ) : donations.length === 0 ? (
                <p className="text-gray-700">Vous n'avez pas encore fait de dons.</p>
            ) : (
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-2">Date du Don</th>
                            <th className="py-2">Nom du Produit</th>
                            <th className="py-2">Quantité</th>
                            <th className="py-2">Catégorie</th>
                            <th className="py-2">Sous-catégorie</th>
                            <th className="py-2">Unité</th>
                            <th className="py-2">Provenance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {donations.map((donation) => (
                            <tr key={donation.id}>
                                <td className="border px-4 py-2">{formatDate(donation.dateAjout)}</td>
                                <td className="border px-4 py-2">{donation.name}</td>
                                <td className="border px-4 py-2">{donation.quantity}</td>
                                <td className="border px-4 py-2">{donation.category}</td>
                                <td className="border px-4 py-2">{donation.subCategory || 'N/A'}</td>
                                <td className="border px-4 py-2">{donation.unit}</td>
                                <td className="border px-4 py-2">{donation.provenance}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <div className="mt-6 flex space-x-4">
                <Link to="/admin/dons/make-donation" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Faire un Don
                </Link>
                <Link to="/admin/tournees/client" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                    Tournées
                </Link>
            </div>
        </div>
    );
};

export default Don;
