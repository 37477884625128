import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const DistributionForm = () => {
    const { id } = useParams();  // Récupérer l'ID de la distribution depuis l'URL
    const [date, setDate] = useState('');
    const [lieu, setLieu] = useState('');
    const [capaciteMaximale, setCapaciteMaximale] = useState('');
    const [status, setStatus] = useState('En cours');
    const [nombreInscrits, setNombreInscrits] = useState(0);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            // Si un ID est présent, récupérer les données de la distribution pour pré-remplir le formulaire
            const fetchDistribution = async () => {
                try {
                    const response = await fetch(`/api/distribution/${id}`);
                    if (!response.ok) {
                        throw new Error('Erreur lors du chargement de la distribution');
                    }
                    const data = await response.json();
                    setDate(data.date);
                    setLieu(data.lieu);
                    setCapaciteMaximale(data.capaciteMaximale);
                    setStatus(data.status);
                    setNombreInscrits(data.nombreInscrits);
                } catch (error) {
                    setMessage(error.message);
                }
            };

            fetchDistribution();
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const distributionData = {
            date,
            lieu,
            capaciteMaximale,
            status,
        };

        try {
            const method = id ? 'PUT' : 'POST';
            const url = id ? `/api/distribution/${id}` : '/api/distributions';

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(distributionData),
            });

            if (response.ok) {
                setMessage('Distribution sauvegardée avec succès !');
                navigate('/admin/distributions');
            } else {
                const errorData = await response.json();
                setMessage(`Erreur : ${errorData.error || 'Une erreur est survenue.'}`);
            }
        } catch (error) {
            console.error('Erreur :', error);
            setMessage('Erreur lors de la sauvegarde de la distribution.');
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">{id ? 'Modifier la distribution' : 'Créer une nouvelle distribution'}</h2>
            {message && <p className="text-red-500">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Date :</label>
                    <input 
                        type="date" 
                        value={date} 
                        onChange={(e) => setDate(e.target.value)} 
                        required 
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Lieu :</label>
                    <input 
                        type="text" 
                        value={lieu} 
                        onChange={(e) => setLieu(e.target.value)} 
                        required 
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Capacité maximale :</label>
                    <input 
                        type="number" 
                        value={capaciteMaximale} 
                        onChange={(e) => setCapaciteMaximale(e.target.value)} 
                        required 
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Statut :</label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    >
                        <option value="En cours">En cours</option>
                        <option value="Terminée">Terminée</option>
                        <option value="Annulée">Annulée</option>
                    </select>
                </div>
                <button 
                    type="submit" 
                    className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
                >
                    {id ? 'Modifier la distribution' : 'Créer la distribution'}
                </button>
            </form>
        </div>
    );
};

export default DistributionForm;
