import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

const TourneeClient = () => {
    const [tournees, setTournees] = useState([]);
    const [error, setError] = useState(null);
    const [filteredTournees, setFilteredTournees] = useState([]);
    const { user } = useAuth();
    const navigate = useNavigate();

    const [filter, setFilter] = useState({
        status: '',
        zone: '',
        month: '',
        year: ''
    });

    useEffect(() => {
        const fetchTournees = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/tournees');
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des tournées');
                }
                const data = await response.json();
                setTournees(data);
                setFilteredTournees(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchTournees();
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const applyFilters = () => {
        let filtered = tournees;

        if (filter.status) {
            filtered = filtered.filter(tournee => tournee.status === filter.status);
        }
        if (filter.zone) {
            filtered = filtered.filter(tournee => tournee.zone === filter.zone);
        }
        if (filter.month) {
            filtered = filtered.filter(tournee => new Date(tournee.date).getMonth() + 1 === parseInt(filter.month));
        }
        if (filter.year) {
            filtered = filtered.filter(tournee => new Date(tournee.date).getFullYear() === parseInt(filter.year));
        }

        setFilteredTournees(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filter]);

    const handleInscription = (id) => {
        navigate(`/admin/tournees/inscription/${id}`);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Tournées Disponibles</h1>
            {error ? (
                <p className="text-red-500">Erreur lors du chargement des tournées : {error}</p>
            ) : (
                <>
                    <div className="mb-4 flex space-x-4">
                        <div>
                            <label className="block text-gray-700">Statut</label>
                            <select 
                                name="status"
                                value={filter.status}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                            >
                                <option value="">Tous les statuts</option>
                                <option value="En cours">En cours</option>
                                <option value="Terminée">Terminée</option>
                                <option value="Annulée">Annulée</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700">Département</label>
                            <input 
                                type="text" 
                                name="zone"
                                value={filter.zone}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Numéro du département"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Mois</label>
                            <input 
                                type="number" 
                                name="month"
                                value={filter.month}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Mois (ex: 1 pour Janvier)"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Année</label>
                            <input 
                                type="number" 
                                name="year"
                                value={filter.year}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Année (ex: 2024)"
                            />
                        </div>
                    </div>

                    {filteredTournees.length === 0 ? (
                        <p className="text-red-500">Aucune tournée trouvée.</p>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
                            {filteredTournees.map((tournee) => (
                                <div key={tournee.id} className="bg-white shadow-lg rounded-lg p-4">
                                    <h2 className="text-xl font-semibold">Tournée du {new Date(tournee.date).toLocaleDateString('fr-FR')}</h2>
                                    <p className="text-sm text-gray-600">Statut: {tournee.status}</p>
                                    <p className="text-sm text-gray-600">Département: {tournee.zone}</p>
                                    <p className="text-sm text-gray-600">Adresse: {tournee.adresse}</p>
                                    <div className="mt-4 flex space-x-4">
                                        <button 
                                            className="bg-green-500 text-white px-4 py-2 rounded"
                                            onClick={() => handleInscription(tournee.id)}
                                        >
                                            S'inscrire
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TourneeClient;
