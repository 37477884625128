import React, { useState } from 'react';

function CreateService() {
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [availability, setAvailability] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        setError('');
        setSuccess('');

        try {
            const response = await fetch('https://nomorewaste.fun/api/benevole/services', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}` // Replace with your auth method
                },
                body: JSON.stringify({
                    type,
                    description,
                    availability
                })
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la création du service.');
            }

            const data = await response.json();
            setSuccess(`Service créé avec succès!`);

            setType('');
            setDescription('');
            setAvailability('');
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="max-w-lg mx-auto p-8 bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-semibold mb-6 text-center text-gray-800">Créer un Service</h1>
            {error && <p className="bg-red-100 text-red-700 p-3 rounded mb-4">{error}</p>}
            {success && <p className="bg-green-100 text-green-700 p-3 rounded mb-4">{success}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                        Type:
                    </label>
                    <input
                        type="text"
                        id="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Description:
                    </label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="availability" className="block text-sm font-medium text-gray-700">
                        Date:
                    </label>
                    <input
                        type="text"
                        id="availability"
                        value={availability}
                        onChange={(e) => setAvailability(e.target.value)}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-green-600 text-white py-2 px-4 rounded-md shadow hover:bg-green-700 transition duration-150"
                >
                    Créer le Service
                </button>
            </form>
        </div>
    );
}

export default CreateService;
