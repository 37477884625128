import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuditAntiGaspiForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        type: 'Commerçant', // Par défaut, on suppose que l'utilisateur est un commerçant
        wastePerWeek: '',
        recycleAwareness: '',
        currentActions: '',
        interestInSolutions: '',
        contactPermission: false
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Envoyer les données à une API ou les traiter localement
        console.log(formData);
        setMessage('Merci pour votre participation à l\'audit anti-gaspillage !');

        // Simuler un délai pour la soumission puis redirection vers le blog
        setTimeout(() => {
            navigate('/Blog');
        }, 2000); // Redirection après 2 secondes

        setFormData({
            name: '',
            type: 'Commerçant',
            wastePerWeek: '',
            recycleAwareness: '',
            currentActions: '',
            interestInSolutions: '',
            contactPermission: false
        });
    };

    return (
        <div className="max-w-4xl mx-auto py-10">
            <h1 className="text-3xl font-bold mb-6">Audit Anti-Gaspillage</h1>
            {message && <p className="mb-4 text-green-500">{message}</p>}
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Nom
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Votre nom"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                        Vous êtes
                    </label>
                    <select
                        id="type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                        <option value="Commerçant">Commerçant</option>
                        <option value="Bénévole">Bénévole</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="wastePerWeek">
                        Quantité de gaspillage par semaine (en kg)
                    </label>
                    <input
                        id="wastePerWeek"
                        name="wastePerWeek"
                        type="number"
                        value={formData.wastePerWeek}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Exemple : 10"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="recycleAwareness">
                        Connaissance des pratiques de recyclage
                    </label>
                    <select
                        id="recycleAwareness"
                        name="recycleAwareness"
                        value={formData.recycleAwareness}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                        <option value="">Sélectionnez un niveau</option>
                        <option value="Débutant">Débutant</option>
                        <option value="Intermédiaire">Intermédiaire</option>
                        <option value="Avancé">Avancé</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currentActions">
                        Actions déjà mises en place pour réduire le gaspillage
                    </label>
                    <textarea
                        id="currentActions"
                        name="currentActions"
                        value={formData.currentActions}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Exemple : Don des invendus à des associations, compostage, etc."
                        rows="3"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="interestInSolutions">
                        Intérêt pour nos solutions de réduction du gaspillage
                    </label>
                    <select
                        id="interestInSolutions"
                        name="interestInSolutions"
                        value={formData.interestInSolutions}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                        <option value="">Sélectionnez un niveau d'intérêt</option>
                        <option value="Faible">Faible</option>
                        <option value="Modéré">Modéré</option>
                        <option value="Élevé">Élevé</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactPermission">
                        Puis-je vous recontacter pour discuter de nos solutions ?
                    </label>
                    <input
                        id="contactPermission"
                        name="contactPermission"
                        type="checkbox"
                        checked={formData.contactPermission}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    /> Oui, j'accepte d'être contacté
                </div>

                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Soumettre l'Audit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AuditAntiGaspiForm;
