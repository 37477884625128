import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ModalStockCarousel = () => {
    const [stocks, setStocks] = useState([]);

    useEffect(() => {
        const fetchStocks = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/stocks');
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des stocks');
                }
                const data = await response.json();
                // Filtrer uniquement les stocks avec le statut "En stock"
                const enStock = data.filter(stock => stock.status === 'En stock');
                setStocks(enStock);
            } catch (error) {
                console.error('Erreur lors du chargement des stocks:', error);
            }
        };

        fetchStocks();
    }, []);

    const getImageForCategory = (category) => {
        switch (category) {
            case 'Viandes':
                return '/images/Viande.jpg';
            case 'Fruits':
                return '/images/Fruits.jpg';
            case 'Légumes':
                return '/images/Legumes.jpg';
            case 'Boissons':
                return '/images/Boissons.jpg';
            case 'Frais':
                return '/images/ProduitsFrais.jpg';
            default:
                return '/images/default.jpg'; // Image par défaut si la catégorie n'est pas reconnue
        }
    };


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="mt-8">
            <h2 className="text-2xl font-bold mb-4">Nos Produits en Stock !</h2>
            {stocks.length > 0 ? (
                <Slider {...settings}>
                    {stocks.map(stock => (
                        <div key={stock.id} className="p-4">
                            
                            <div className="bg-white shadow-lg rounded-lg ">
                            <img
                                    src={getImageForCategory(stock.category)}
                                    alt={stock.name}
                                    className="w-full h-56 object-cover object-bottom rounded-lg mb-4"

                                />
                                <div className="p-4">
                                <h3 className="text-lg font-semibold mb-2">{stock.name}</h3>
                                <p className="text-sm text-gray-600">Quantité: {stock.quantity}</p>
                                <p className="text-sm text-gray-600">Catégorie: {stock.category}</p>
                                {stock.subCategory && <p className="text-sm text-gray-600">Sous-catégorie: {stock.subCategory}</p>}
                                {stock.unit && <p className="text-sm text-gray-600">Unité: {stock.unit}</p>}
                                <p className="text-sm text-gray-600">Provenance: {stock.provenance}</p>
                                <p className="text-sm text-gray-600">Statut: {stock.status}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                <p className="text-sm text-gray-600">Aucun produit en stock pour le moment.</p>
            )}
        </div>
    );
};

export default ModalStockCarousel;
