import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function ResaCookingClass() {
    const { id } = useParams(); // Obtenir l'id du cours de cuisine à partir de l'URL
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch(`https://nomorewaste.fun/api/booking/by-cooking-class/${id}`);
                if (!response.ok) {
                    const text = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                }
                const data = await response.json();
                setBookings(data);
                console.log('Bookings fetched successfully:', data);
            } catch (error) {
                console.error('Error fetching bookings:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, [id]);

    if (loading) {
        return <p className="text-center text-gray-500">Loading bookings...</p>;
    }

    if (error) {
        return <p className="text-center text-red-500">Error loading bookings: {error}</p>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Réservations pour le cours de cuisine</h1>
            {bookings.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {bookings.map((booking) => (
                        <div key={booking.id} className="bg-white shadow-lg rounded-lg p-6">
                            <h2 className="text-xl font-semibold mb-2">{booking.userName}</h2>
                            <p className="text-gray-700 mb-1"><strong>Email:</strong> {booking.userEmail}</p>
                            <p className="text-gray-700 mb-1"><strong>Type d'utilisateur:</strong> {booking.userType}</p>
                            <p className="text-gray-700"><strong>Date de réservation:</strong> {new Date(booking.bookedAt).toLocaleString()}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-center text-gray-500">Aucune réservation pour ce cours de cuisine.</p>
            )}
        </div>
    );
}

export default ResaCookingClass;
