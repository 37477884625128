import React, { useState, useEffect } from 'react';

const GestionServices = () => {
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [volunteers, setVolunteers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/admin/services');
                if (!response.ok) throw new Error('Erreur lors de la récupération des services.');
                const data = await response.json();
                setServices(data);
            } catch (err) {
                setError('Erreur lors de la récupération des services.');
                console.error(err);
            }
        };

        fetchServices();
    }, []);

    const handleSelectService = async (serviceId) => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`https://nomorewaste.fun/api/admin/services/${serviceId}`);
            if (!response.ok) throw new Error('Erreur lors de la récupération des détails du service.');
            const serviceData = await response.json();
            setSelectedService(serviceData);

            // Fetch volunteers
            const volunteersResponse = await fetch('https://nomorewaste.fun/api/admin/benevoles');
            if (!volunteersResponse.ok) throw new Error('Erreur lors de la récupération des bénévoles.');
            const volunteersData = await volunteersResponse.json();
            setVolunteers(volunteersData);
        } catch (err) {
            setError('Erreur lors de la récupération des détails du service.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleAcceptService = async (serviceId) => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`https://nomorewaste.funs/api/admin/services/${serviceId}/validate`, {
                method: 'POST',
            });
            if (!response.ok) throw new Error('Erreur lors de l\'acceptation du service.');

            // Update local state
            setServices(services.map(service =>
                service.id === serviceId ? { ...service, isValidated: true } : service
            ));
            setSelectedService(prevState => ({
                ...prevState,
                isValidated: true
            }));
        } catch (err) {
            setError('Erreur lors de l\'acceptation du service.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleRejectService = async (serviceId) => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`https://nomorewaste.fun/api/admin/services/${serviceId}`, {
                method: 'DELETE',
            });
            if (!response.ok) throw new Error('Erreur lors du refus du service.');

            // Update local state
            setServices(services.filter(service => service.id !== serviceId));
            setSelectedService(null);
        } catch (err) {
            setError('Erreur lors du refus du service.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-100 py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-8">
                    Gestion des Services
                </h1>
                {error && <p className="text-red-500 text-center">{error}</p>}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
                    {services.map(service => (
                        <div key={service.id} className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-2">{service.type}</h2>
                            <p className="text-gray-700 mb-4">{service.description}</p>
                            <p className="text-gray-500 mb-4"><strong>Disponibilité:</strong> {service.availability}</p>
                            <button
                                onClick={() => handleSelectService(service.id)}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            >
                                Détails
                            </button>
                        </div>
                    ))}
                </div>
                
                {selectedService && (
                    <div className="bg-white p-6 rounded-lg shadow-lg mt-8">
                        <h2 className="text-xl font-bold mb-4">Détails du Service</h2>
                        <p><strong>Type:</strong> {selectedService.type}</p>
                        <p><strong>Description:</strong> {selectedService.description}</p>
                        <p><strong>Disponibilité:</strong> {selectedService.availability}</p>
                        <p><strong>Validé:</strong> {selectedService.isValidated ? 'Oui' : 'Non'}</p>
                        <button
                            onClick={() => handleAcceptService(selectedService.id)}
                            className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'En cours...' : 'Accepter'}
                        </button>
                        <button
                            onClick={() => handleRejectService(selectedService.id)}
                            className={`ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'En cours...' : 'Refuser'}
                        </button>

                        {selectedService.isValidated && (
                            <div className="mt-4">
                                <h3 className="text-lg font-bold mb-2">Bénévoles inscrits au service</h3>
                                <ul>
                                    {volunteers.map(volunteer => (
                                        <li key={volunteer.id} className="mb-2">
                                            {volunteer.nom} ({volunteer.email})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default GestionServices;
