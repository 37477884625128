import React, { useEffect, useState } from 'react';

const ListeAdhesions = () => {
    const [adherents, setAdherents] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAdherents = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/adhesions');
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des adhérents');
                }
                const data = await response.json();
                setAdherents(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchAdherents();
    }, []);

    const handleSuspend = async (id) => {
        try {
            const response = await fetch(`https://nomorewaste.fun/admin/adhesions/suspend/${id}`, { method: 'POST' });
            if (response.ok) {
                setAdherents(adherents.map(adherent =>
                    adherent.id === id ? { ...adherent, userType: 'suspendu' } : adherent
                ));
            } else {
                console.error("Erreur lors de la suspension de l'adhérent:", response.statusText);
            }
        } catch (error) {
            console.error("Erreur lors de la suspension de l'adhérent:", error);
        }
    };

    const handleBan = async (id) => {
        try {
            const response = await fetch(`/admin/adhesions/ban/${id}`, { method: 'POST' });
            if (response.ok) {
                setAdherents(adherents.map(adherent =>
                    adherent.id === id ? { ...adherent, userType: 'banni' } : adherent
                ));
            } else {
                console.error("Erreur lors du bannissement de l'adhérent:", response.statusText);
            }
        } catch (error) {
            console.error("Erreur lors du bannissement de l'adhérent:", error);
        }
    };

    const handleEdit = (id) => {
        // Logique pour modifier l'adhérent
        console.log(`Modifier l'adhérent avec l'ID: ${id}`);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Gestion des Adhésions</h1>
            {error ? (
                <p className="text-red-500">Erreur lors du chargement des adhérents : {error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {adherents.map((adherent) => (
                        <div key={adherent.id} className="bg-white shadow-lg rounded-lg p-4">
                            <h2 className="text-xl font-semibold">{adherent.name}</h2>
                            <p className="text-sm text-gray-600">Email: {adherent.email}</p>
                            <p className="text-sm text-gray-600">Date d'adhésion: {new Date(adherent.createdAt).toLocaleDateString()}</p>
                            <div className="mt-4">
                                <button 
                                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                                    onClick={() => handleEdit(adherent.id)}
                                >
                                    Modifier
                                </button>
                                <button 
                                    className="bg-yellow-500 text-white px-4 py-2 rounded mr-2"
                                    onClick={() => handleSuspend(adherent.id)}
                                >
                                    Suspendre
                                </button>
                                <button 
                                    className="bg-red-500 text-white px-4 py-2 rounded"
                                    onClick={() => handleBan(adherent.id)}
                                >
                                    Bannir
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ListeAdhesions;
