import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth/AuthContext'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom';

const UserCreateCookingClass = () => {
    const { user } = useAuth(); // Récupère les informations de l'utilisateur authentifié
    const navigate = useNavigate();

    console.log("UserCreateCookingClass -> user", user);

    // Vérifiez si l'utilisateur est un bénévole
    useEffect(() => {
        if (!user || user.user_type !== 'bénévole') {
            // Redirigez l'utilisateur s'il n'est pas un bénévole
            navigate('/unauthorized');
        }
    }, [user, navigate]);

    const [cookingClassData, setCookingClassData] = useState({
        title: '',
        description: '',
        startTime: '',
        duration: '',
        maxParticipants: '',
        volunteerId: user ? user.id : '', // Par défaut, l'ID du bénévole est celui de l'utilisateur authentifié
    });

    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCookingClassData({
            ...cookingClassData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://nomorewaste.fun/api/create-cooking-class', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cookingClassData),
            });

            if (response.ok) {
                setMessage('Cours de cuisine créé avec succès !');
                // Réinitialisez les champs du formulaire après création
                setCookingClassData({
                    title: '',
                    description: '',
                    startTime: '',
                    duration: '',
                    maxParticipants: '',
                    volunteerId: user ? user.id : '',
                });
                // Rediriger vers la liste des cours de cuisine après création
                navigate('/admin/cooking-classes');
            } else {
                setMessage('Échec de la création du cours de cuisine.');
            }
        } catch (error) {
            console.error('Erreur lors de la création du cours de cuisine:', error);
            setMessage('Une erreur est survenue lors de la création du cours de cuisine.');
        }
    };

    return (
        <div className="max-w-4xl mx-auto py-10">
            <h1 className="text-3xl font-bold mb-6 text-center">Créer un Nouveau Cours de Cuisine</h1>
            {message && <p className="mb-4 text-green-500 text-center">{message}</p>}
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                        Titre
                    </label>
                    <input
                        id="title"
                        type="text"
                        name="title"
                        value={cookingClassData.title}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Titre du cours"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        value={cookingClassData.description}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Description du cours"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startTime">
                        Heure de Début
                    </label>
                    <input
                        id="startTime"
                        type="datetime-local"
                        name="startTime"
                        value={cookingClassData.startTime}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="duration">
                        Durée (minutes)
                    </label>
                    <input
                        id="duration"
                        type="number"
                        name="duration"
                        value={cookingClassData.duration}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Durée du cours"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="maxParticipants">
                        Participants Maximum
                    </label>
                    <input
                        id="maxParticipants"
                        type="number"
                        name="maxParticipants"
                        value={cookingClassData.maxParticipants}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Nombre maximum de participants"
                        required
                    />
                </div>

                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-150"
                >
                    Créer le Cours de Cuisine
                </button>
            </form>
        </div>
    );
};

export default UserCreateCookingClass;
