

import React, { useState, useEffect } from 'react';

const GestionBenevoles = () => {
    const [benevoles, setBenevoles] = useState([]);
    const [selectedBenevole, setSelectedBenevole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchBenevoles = async () => {
            try {
                const response = await fetch('/api/admin/benevoles');
                if (!response.ok) throw new Error('Erreur lors de la récupération des bénévoles.');
                const data = await response.json();
                setBenevoles(data);
            } catch (err) {
                setError('Erreur lors de la récupération des bénévoles.');
                console.error(err);
            }
        };

        fetchBenevoles();
    }, []);

    const handleSelectBenevole = async (benevoleId) => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`/api/admin/benevoles/${benevoleId}`);
            if (!response.ok) throw new Error('Erreur lors de la récupération des détails du bénévole.');
            const benevoleData = await response.json();
            setSelectedBenevole(benevoleData);
        } catch (err) {
            setError('Erreur lors de la récupération des détails du bénévole.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleBanBenevole = async (benevoleId) => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`/api/admin/benevoles/${benevoleId}/ban`, {
                method: 'POST',
            });
            if (!response.ok) throw new Error('Erreur lors de la suppression du bénévole.');
            
            // Remove banned benevole from list
            setBenevoles(benevoles.filter(benevole => benevole.id !== benevoleId));
            setSelectedBenevole(null);
        } catch (err) {
            setError('Erreur lors de la suppression du bénévole.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-100 py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-8">
                    Gestion des Bénévoles
                </h1>
                {error && <p className="text-red-500 text-center">{error}</p>}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
                    {benevoles.map(benevole => (
                        <div key={benevole.id} className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-2">{benevole.nom}</h2>
                            <p className="text-gray-700 mb-4">{benevole.email}</p>
                            <button
                                onClick={() => handleSelectBenevole(benevole.id)}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                            >
                                Détails
                            </button>
                            <button
                                onClick={() => handleBanBenevole(benevole.id)}
                                className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                            >
                                Bannir
                            </button>
                        </div>
                    ))}
                </div>
                
                {selectedBenevole && (
                    <div className="bg-white p-6 rounded-lg shadow-lg mt-8">
                        <h2 className="text-xl font-bold mb-4">Détails du Bénévole</h2>
                        <p><strong>Nom:</strong> {selectedBenevole.nom}</p>
                        <p><strong>Email:</strong> {selectedBenevole.email}</p>
                        <p><strong>Compétences:</strong> {selectedBenevole.skills}</p>
                        <p><strong>Photo de Profil:</strong> <img src={selectedBenevole.photoDeProfil} alt="Photo de Profil" className="w-32 h-32 object-cover rounded-full" /></p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GestionBenevoles;
