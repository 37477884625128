import React from 'react';

const DeleteConseilModal = ({ conseil, onClose, onDelete }) => {
    const handleDelete = async () => {
        try {
            const response = await fetch(`https://nomorewaste.fun/api/conseils/${conseil.id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                onDelete();
                onClose();
            } else {
                console.error('Failed to delete conseil');
            }
        } catch (error) {
            console.error('Error deleting conseil:', error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-2xl mb-4">Supprimer le Conseil</h2>
                <p>Êtes-vous sûr de vouloir supprimer ce conseil ? Cette action est irréversible.</p>
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="mr-2 px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={handleDelete}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteConseilModal;
