import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CreationConseilAntiGaspi = () => {
  const [typeConseil, setTypeConseil] = useState('');
  const [titre, setTitre] = useState('');
  const [auteur, setAuteur] = useState('');
  const [date, setDate] = useState('');
  const [texte, setTexte] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Trim the values to remove leading and trailing whitespace
    const trimmedTypeConseil = typeConseil.trim();
    const trimmedTitre = titre.trim();
    const trimmedAuteur = auteur.trim();
    const trimmedTexte = texte.trim();
  
    // Check if any field is empty after trimming
    if (!trimmedTypeConseil || !trimmedTitre || !trimmedAuteur || !trimmedTexte) {
      setMessage('Veuillez remplir tous les champs correctement sans espaces inutiles.');
      return;
    }
  
    const conseilData = {
      typeConseil: trimmedTypeConseil,
      titre: trimmedTitre,
      auteur: trimmedAuteur,
      date,
      texte: trimmedTexte,
    };
  
    try {
      const response = await fetch('https://nomorewaste.fun/api/conseils', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(conseilData),
      });
  
      if (response.ok) {
        setMessage('Conseil créé avec succès !');
        setTypeConseil('');
        setTitre('');
        setAuteur('');
        setDate('');
        setTexte('');
      } else {
        setMessage('Erreur lors de la création du conseil.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Erreur lors de la création du conseil.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-10">
      <h1 className="text-3xl font-bold mb-6">Ajouter un Conseil Anti-Gaspillage</h1>
      {message && <p className="mb-4 text-green-500">{message}</p>}
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="typeConseil">
            Type de Conseil
          </label>
          <input
            id="typeConseil"
            type="text"
            value={typeConseil}
            onChange={(e) => setTypeConseil(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Ex: Alimentaire, Énergie, Recyclage..."
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="titre">
            Titre
          </label>
          <input
            id="titre"
            type="text"
            value={titre}
            onChange={(e) => setTitre(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Titre du conseil"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold  mb-2" htmlFor="auteur">
            Auteur
          </label>
          <input
            id="auteur"
            type="text"
            value={auteur}
            onChange={(e) => setAuteur(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Nom de l'auteur"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
            Date
          </label>
          <input
            id="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="texte">
            Texte du Conseil
          </label>
          <textarea
            id="texte"
            value={texte}
            onChange={(e) => setTexte(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Écrivez votre conseil ici..."
            rows="5"
            required
          ></textarea>

        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Ajouter le Conseil
          </button>

          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
           <Link to="/admin/liste-conseil-anti-gaspi">Voir les Conseils</Link>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreationConseilAntiGaspi;
