import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const TourneeForm = () => {
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('');
    const [zone, setZone] = useState('');  
    const [adresse, setAdresse] = useState('');  
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            const fetchTournee = async () => {
                try {
                    const response = await fetch(`/api/tournee/${id}`);
                    if (!response.ok) {
                        throw new Error('Erreur lors du chargement de la tournée');
                    }
                    const data = await response.json();
                    setDate(new Date(data.date).toISOString().split('T')[0]);
                    setStatus(data.status);
                    setZone(data.zone);  
                    setAdresse(data.adresse);  
                } catch (error) {
                    setError(error.message);
                }
            };

            fetchTournee();
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestData = {
            date: new Date(date).toISOString(),
            status,
            zone,  
            adresse  
        };

        try {
            const response = await fetch(id ? `/api/tournee/${id}` : '/api/tournee', {
                method: id ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la sauvegarde de la tournée');
            }

            navigate('/admin/tournees');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">{id ? 'Modifier la Tournée' : 'Créer une Nouvelle Tournée'}</h1>
            {error && <p className="text-red-500">Erreur : {error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Date</label>
                    <input
                        type="date"
                        className="shadow border rounded py-2 px-3 text-gray-700"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Statut</label>
                    <select
                        className="shadow border rounded py-2 px-3 text-gray-700"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                    >
                        <option value="En cours">En cours</option>
                        <option value="Terminée">Terminée</option>
                        <option value="Annulée">Annulée</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Zone (Numéro de Département)</label>
                    <input
                        type="text"
                        className="shadow border rounded py-2 px-3 text-gray-700"
                        value={zone}
                        onChange={(e) => setZone(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Adresse</label>
                    <input
                        type="text"
                        className="shadow border rounded py-2 px-3 text-gray-700"
                        value={adresse}
                        onChange={(e) => setAdresse(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    {id ? 'Modifier' : 'Créer'}
                </button>
            </form>
        </div>
    );
};

export default TourneeForm;
