import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

const InscriptionTournee = () => {
    const { id: tourneeId } = useParams(); // Récupère l'id de la tournée depuis l'URL
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const { user } = useAuth(); // Récupère l'utilisateur authentifié

    useEffect(() => {
        // Vérifie si l'utilisateur est authentifié
        if (!user || !user.id) {
            setMessage('Erreur: Utilisateur non authentifié. Vous allez être redirigé.');
            // Redirige vers la page de connexion après 3 secondes
            setTimeout(() => navigate('/connexion'), 3000);
        } else {
            // Préremplir les champs avec les informations de l'utilisateur s'il est connecté
            setNom(user.name || '');
            setEmail(user.email || '');
            setTelephone(user.telephone || '');
            
        }
    }, [user, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user || !user.id) {
            setMessage('Erreur: Utilisateur non authentifié.');
            return;
        }

        console.log('user', user);

        const inscriptionData = {
            userId: user.id,
            nom,
            email,
            telephone,
            tourneeId,
        };

        try {
            const response = await fetch('https://nomorewaste.fun/api/inscription-tournee', {
                method: 'POST',  // Utilisation explicite de la méthode POST
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(inscriptionData),  // Envoi des données en JSON
            });

            if (response.ok) {
                setMessage('Inscription réussie!');
                navigate('/admin/tournees/client'); // Redirige vers la liste des tournées après inscription
            } else {
                const errorData = await response.json();
                setMessage(`Erreur lors de l'inscription: ${errorData.error || 'Une erreur est survenue.'}`);
            }
        } catch (error) {
            console.error('Erreur:', error);
            setMessage('Erreur lors de l\'inscription.');
        }
    };

    if (!user || !user.id) {
        return <p className="text-red-500">{message}</p>; // Affiche un message d'erreur si l'utilisateur n'est pas authentifié
    }

    return (
        <div className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-md">
            <h2 className="text-2xl font-bold mb-6">Inscription à la tournée</h2>
            {message && <p className="text-red-600 mb-4">{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Nom:</label>
                    <input 
                        type="text" 
                        value={nom} 
                        onChange={(e) => setNom(e.target.value)} 
                        required 
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Email:</label>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-2">Téléphone:</label>
                    <input 
                        type="tel" 
                        value={telephone} 
                        onChange={(e) => setTelephone(e.target.value)} 
                        required 
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                </div>
                <button 
                    type="submit" 
                    className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
                >
                    S'inscrire
                </button>
            </form>
        </div>
    );
};

export default InscriptionTournee;
