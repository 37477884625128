import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

const DonationForm = () => {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [unit, setUnit] = useState('');
    const [status, setStatus] = useState('Pending'); // Statut par défaut
    const [date, setDate] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const { user } = useAuth(); // Récupère l'utilisateur authentifié

    useEffect(() => {
        // Vérifie si l'utilisateur est authentifié
        if (!user || !user.id) {
            setMessage('Erreur: Utilisateur non authentifié. Vous allez être redirigé.');
            // Redirige vers la page de connexion après 3 secondes
            setTimeout(() => navigate('/connexion'), 3000);
        }
    }, [user, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user || !user.id) {
            setMessage('Erreur: Utilisateur non authentifié.');
            return;
        }

        const donationData = {
            user_id: user.id,
            name,
            quantity,
            category,
            subCategory,
            unit,
            date,
            status, // Ajout du statut dans les données envoyées
        };

        try {
            const response = await fetch('https://nomorewaste.fun/api/dons/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(donationData),
            });

            if (response.ok) {
                setMessage('Don ajouté avec succès !');
                setName('');
                setQuantity('');
                setCategory('');
                setSubCategory('');
                setUnit('');
                setDate('');
                setStatus('Pending');
                navigate('/admin/dons');
            } else {
                const errorData = await response.json();
                setMessage(`Erreur lors de l'ajout du don: ${errorData.error || 'Une erreur est survenue.'}`);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Erreur lors de l\'ajout du don.');
        }
    };

    if (!user || !user.id) {
        return <p className="text-red-500">{message}</p>; // Affiche un message d'erreur si l'utilisateur n'est pas authentifié
    }

    return (
        <div className="max-w-4xl mx-auto py-10">
            <h1 className="text-3xl font-bold mb-6">Faire un Don</h1>
            {message && <p className="mb-4 text-red-500">{message}</p>}
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Nom du Produit
                    </label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Nom du produit"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
                        Quantité
                    </label>
                    <input
                        id="quantity"
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Quantité"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                        Catégorie
                    </label>
                    <select
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    >
                        <option value="">Sélectionnez une catégorie</option>
                        <option value="Frais">Produits Frais</option>
                        <option value="Légumes">Légumes</option>
                        <option value="Viandes">Viandes</option>
                        <option value="Fruits">Fruits</option>
                        <option value="Boissons">Boissons</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subCategory">
                        Sous-catégorie (facultatif)
                    </label>
                    <input
                        id="subCategory"
                        type="text"
                        value={subCategory}
                        onChange={(e) => setSubCategory(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Sous-catégorie du produit"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="unit">
                        Unité
                    </label>
                    <input
                        id="unit"
                        type="text"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Unité (ex: kg, litres, unités)"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                        Date du Don
                    </label>
                    <input
                        id="date"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                        Statut
                    </label>
                    <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    >
                        <option value="Pending">En attente</option>
                    </select>
                </div>

                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Ajouter le Don
                    </button>
                </div>
            </form>
        </div>
    );
};

export default DonationForm;
