import React from 'react';
import HeroSection from '../components/HeroSection';

function Home() {
  return (
     <div className="bg-white">

      <HeroSection />

    </div>
  );
}

export default Home;
