import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

// Créer le contexte
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Vérifie si l'utilisateur est connecté via un cookie
        const storedUser = Cookies.get('user');
        console.log('Stored User:', storedUser);  // Debug log

        if (storedUser) {
            try {
                const parsedUser = JSON.parse(storedUser);
                // Vérification et assignation du user_type
                if (parsedUser.user_type) {
                    setUser(parsedUser);
                } else {
                    console.error('User type is missing in stored user data');
                }
            } catch (error) {
                console.error('Error parsing user data from cookie:', error);
            }
        }
    }, []);

    const login = (userData) => {
        if (userData && userData.user_type) {
            setUser(userData);
            Cookies.set('user', JSON.stringify(userData), { expires: 1 }); // Stocke l'utilisateur dans un cookie pour 1 jour
        } else {
            console.error('login called with undefined or incomplete userData');
        }
    };

    const logout = () => {
        setUser(null);
        Cookies.remove('user'); // Supprime le cookie utilisateur
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook pour utiliser le AuthContext
export const useAuth = () => React.useContext(AuthContext);
