import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StatusModal from './StatusModal';  // Assurez-vous d'importer la modale correctement

const GestionStocks = () => {
    const [stocks, setStocks] = useState([]);
    const [error, setError] = useState(null);
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [filter, setFilter] = useState({
        category: '',
        subCategory: '',
        unit: ''
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStock, setSelectedStock] = useState(null);

    useEffect(() => {
        const fetchStocks = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/stocks');
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des stocks');
                }
                const data = await response.json();
                setStocks(data);
                setFilteredStocks(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchStocks();
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const applyFilters = () => {
        let filtered = stocks;

        if (filter.category) {
            filtered = filtered.filter(stock => stock.category === filter.category);
        }

        if (filter.subCategory) {
            filtered = filtered.filter(stock => stock.subCategory === filter.subCategory);
        }

        if (filter.unit) {
            filtered = filtered.filter(stock => stock.unit === filter.unit);
        }

        setFilteredStocks(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filter]);

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://nomorewaste.fun/api/stocks/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setStocks(stocks.filter(stock => stock.id !== id));
                setFilteredStocks(filteredStocks.filter(stock => stock.id !== id));
            } else {
                console.error("Erreur lors de la suppression du produit:", response.statusText);
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du produit:", error);
        }
    };

    const openModal = (stock) => {
        setSelectedStock(stock);
        setIsModalOpen(true);
    };

    const updateStockStatus = async (id, status) => {
        try {
            const response = await fetch(`https://nomorewaste.fun/api/stocks/${id}/status`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status })
            });

            if (response.ok) {
                setStocks(stocks.map(stock =>
                    stock.id === id ? { ...stock, status } : stock
                ));
                setFilteredStocks(filteredStocks.map(stock =>
                    stock.id === id ? { ...stock, status } : stock
                ));
            } else {
                console.error("Erreur lors de la mise à jour du statut du produit:", response.statusText);
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut du produit:", error);
        }
    };

    const handleSaveStatus = (status) => {
        if (selectedStock) {
            updateStockStatus(selectedStock.id, status);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Gestion des Stocks</h1>
            {error ? (
                <p className="text-red-500">Erreur lors du chargement des stocks : {error}</p>
            ) : (
                <>
                    <div className="mb-4 flex space-x-4">
                        <div>
                            <label className="block text-gray-700">Catégorie</label>
                            <select 
                                name="category"
                                value={filter.category}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                            >
                                <option value="">Toutes les catégories</option>
                                <option value="Frais">Produits Frais</option>
                                <option value="Légumes">Légumes</option>
                                <option value="Viandes">Viandes</option>
                                <option value="Fruits">Fruits</option>
                                <option value="Boissons">Boissons</option>
                            </select>
                        </div>

                        <div>
                            <label className="block text-gray-700">Sous-catégorie</label>
                            <input
                                type="text"
                                name="subCategory"
                                value={filter.subCategory}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Sous-catégorie"
                            />
                        </div>

                        <div>
                            <label className="block text-gray-700">Unité</label>
                            <input
                                type="text"
                                name="unit"
                                value={filter.unit}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Unité"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
                        {filteredStocks.map((stock) => (
                            <div key={stock.id} className="bg-white shadow-lg rounded-lg p-4">
                                <h2 className="text-xl font-semibold">{stock.name}</h2>
                                <p className="text-sm text-gray-600">Quantité: {stock.quantity}</p>
                                <p className="text-sm text-gray-600">Catégorie: {stock.category}</p>
                                {stock.subCategory && <p className="text-sm text-gray-600">Sous-catégorie: {stock.subCategory}</p>}
                                {stock.unit && <p className="text-sm text-gray-600">Unité: {stock.unit}</p>}
                                <p className="text-sm text-gray-600">Provenance: {stock.provenance}</p>
                                <p className="text-sm text-gray-600">Statut: {stock.status}</p> {/* Affichage du statut */}
                                <div className="mt-4 flex space-x-4">
                                    {stock.status === 'Pending' && (
                                        <button 
                                            className="bg-green-500 text-white px-4 py-2 rounded"
                                            onClick={() => updateStockStatus(stock.id, 'En stock')}
                                        >
                                            Reçu
                                        </button>
                                    )}
                                    <button 
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                        onClick={() => openModal(stock)}
                                    >
                                        Modifier Statut
                                    </button>
                                    <button 
                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                        onClick={() => handleDelete(stock.id)}
                                    >
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"> 
                <Link to="/admin/CreationStock">Ajouter un nouveau produit</Link>
            </button>

            {/* Modale pour modifier le statut */}
            <StatusModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                currentStatus={selectedStock ? selectedStock.status : 'Pending'}
                onSave={handleSaveStatus}
            />
        </div>
    );
};

export default GestionStocks;
