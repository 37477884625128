import React, { useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react'; // Adjust the import to default
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Calendar } from '@fullcalendar/core';


function ServiceCalendar() {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                // Utilisez l'URL appropriée pour récupérer les services inscrits
                const response = await fetch('https://nomorewaste.fun/api/benevole/services/inscrits');
                if (!response.ok) throw new Error('Erreur lors de la récupération des services.');
                const services = await response.json();

                const calendarEvents = services.map(service => ({
                    title: service.type,
                    start: service.availability, // Assurez-vous que la date est au bon format
                    // Vous pouvez ajouter d'autres attributs comme end, description, etc.
                }));

                setEvents(calendarEvents);
            } catch (error) {
                console.error(error);
            }
        };

        fetchServices();
    }, []);

    return (
        <div className="calendar">
            <h1>Calendrier des Services</h1>
            <Calendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
            />
        </div>
    );
}

export default ServiceCalendar;
