import React from 'react';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <section className="relative bg-cover bg-center text-[#B1702E] py-20 text-center" style={{ backgroundImage: "url('/images/Bouchons.jpg')" }}>
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Filtre noir semi-transparent pour améliorer la lisibilité du texte */}
      <div className="relative max-w-screen-xl mx-auto px-4">
        <h1 className="text-5xl font-bold text-white">No More Waste</h1>
        <p className="text-xl mt-4 text-white">Ensemble, luttons contre le gaspillage alimentaire et aidons ceux qui en ont le plus besoin.</p>
        <div className="mt-8 space-x-4">
          <Link to="/inscription" className="bg-[#B1702E] text-white py-3 px-6 rounded-lg hover:bg-[#A15B1B]">S'inscrire</Link>
          <Link to="/about-us" className="border border-white text-white py-3 px-6 rounded-lg hover:bg-white hover:text-[#B1702E]">En savoir plus</Link>
        </div>
      </div>
    </section>
  );
}

function VousEtesSection() {
  return (
    <section className="py-20 bg-white text-center">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-[#B1702E] mb-10">Vous êtes :</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="flex flex-col items-center">
            <h3 className="text-2xl font-semibold text-[#B1702E]">Acteur de la grande distribution</h3>
            <p className="mt-2 text-gray-600 text-center">Réduisez vos biodéchets, valorisez votre casse et ne perdez plus d'argent avec vos invendus.</p>
            <a href="#" className="text-[#B1702E] mt-4 block">Faire des économies</a>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-2xl font-semibold text-[#B1702E]">Industriel ou fabricant</h3>
            <p className="mt-2 text-gray-600 text-center">Valorisez vos surplus, invendus ou stocks dormants, alimentaires ou non-alimentaires, en toute simplicité !</p>
            <a href="#" className="text-[#B1702E] mt-4 block">Déstocker de gros volumes</a>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-2xl font-semibold text-[#B1702E]">Commerce de proximité</h3>
            <p className="mt-2 text-gray-600 text-center">Vendez des paniers anti-gaspi sur notre application mobile, générez un chiffre d'affaires supplémentaire et attirez une nouvelle clientèle.</p>
            <a href="#" className="text-[#B1702E] mt-4 block">Vendre des paniers anti-gaspi</a>
          </div>
        </div>
      </div>
    </section>
  );
}

function ServicesSection() {
  return (
    <section className="py-20 bg-white text-center">
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row md:space-x-10">
        <img src="/cook.jpg" alt="Cours de cuisine" className="w-full md:w-1/2 object-cover rounded-lg mb-8 md:mb-0" />
        <div className="flex flex-col justify-center">
          <h2 className="text-4xl font-bold text-[#B1702E] mb-6">Nos Services</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-2xl font-semibold text-[#B1702E]">Récolte des invendus</h3>
              <p className="mt-2 text-gray-600">Nous récupérons les invendus des commerçants pour les redistribuer aux plus démunis.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-[#B1702E]">Partage de ressources</h3>
              <p className="mt-2 text-gray-600">Nous offrons des solutions de partage de véhicules, d'échange de services et bien plus.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-[#B1702E]">Cours de cuisine anti-gaspi</h3>
              <p className="mt-2 text-gray-600">Apprenez à cuisiner de manière responsable en utilisant des produits récupérés.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function LivraisonSection() {
  return (
    <section className="py-20 bg-white text-center">
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row md:space-x-10">
        <div className="flex flex-col justify-center">
          <h2 className="text-4xl font-bold text-[#B1702E] mb-6">Livraison et Distribution</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-2xl font-semibold text-[#B1702E]">Service de Livraison</h3>
              <p className="mt-2 text-gray-600">Nous proposons un service de livraison rapide et fiable pour nos produits anti-gaspi.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-[#B1702E]">Distribution locale</h3>
              <p className="mt-2 text-gray-600">Nos équipes assurent une distribution efficace et locale, maximisant ainsi l'impact des dons.</p>
            </div>
          </div>
        </div>
        <img src="/livraison.jpg" alt="Livraison" className="w-full md:w-1/2 object-cover rounded-lg mt-8 md:mt-0" />
      </div>
    </section>
  );
}


function ExpertiseSection() {
  return (
    <section className="py-20 bg-white text-center">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-[#B1702E] mb-10">Votre métier n’est pas de gérer les invendus. C’est le nôtre.</h2>
        <p className="text-lg text-gray-700">Les invendus sont un manque à gagner important pour les professionnels. Mais leur gestion est complexe, et nécessite du temps et des ressources. Chez No More Waste, on en a fait notre métier.</p>
        <Link
          to="/Auditantigaspi"
          className="mt-8 inline-block bg-[#B1702E] text-white py-3 px-6 rounded-lg hover:bg-[#A15B1B]"
        >
          Auditer ma démarche anti-gaspi
        </Link>
      </div>
    </section>
  );
}


function HomePage() {
  return (
    <div>
      <HeroSection />
      <VousEtesSection />
      <ServicesSection />
      <LivraisonSection />
      <ExpertiseSection />
    </div>
  );
}

export default HomePage;
