import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  return (
    <div
      className="relative bg-cover bg-center min-h-screen flex flex-col items-center justify-center text-white"
      style={{ backgroundImage: "url('/images/lost.png')" }}
    >
      <div className="absolute inset-0 bg-black opacity-60"></div> {/* Filtre noir semi-transparent */}
      <div className="relative text-center">
        <h1 className="text-8xl font-bold">404</h1>
        <p className="text-2xl mt-4">Oups ! La page que vous recherchez est introuvable ou vous n'y avez pas accès.</p>
        <p className="text-xl mt-2">Il semble que la page que vous essayez de visiter n'existe pas pour vous.</p>
        <Link
          to="/"
          className="mt-8 inline-block bg-[#B1702E] text-white py-3 px-6 rounded-lg hover:bg-[#A15B1B]"
        >
          Retour à l'accueil
        </Link>
      </div>
    </div>
  );
};

export default Error404;
