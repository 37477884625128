import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EditConseilModal from './EditConseilModal';
import DeleteConseilModal from './DeleteConseilModal';

const ListeConseilAntiGaspi = () => {
    const [conseils, setConseils] = useState([]);
    const [error, setError] = useState(null);
    const [selectedConseil, setSelectedConseil] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        const fetchConseils = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/list-conseils');
                if (!response.ok) {
                    const text = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                }
                const data = await response.json();
                setConseils(data);
                console.log('Conseils fetched successfully:', data);
            } catch (error) {
                console.error('Error fetching conseils:', error);
                setError(error.message);
            }
        };

        fetchConseils();
    }, []);

    const handleEditClick = (conseil) => {
        setSelectedConseil(conseil);
        setShowEditModal(true);
    };

    const handleDeleteClick = (conseil) => {
        setSelectedConseil(conseil);
        setShowDeleteModal(true);
    };

    const handleSave = () => {
        // Refresh the list of conseils after saving
        const fetchConseils = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/list-conseils');
                const data = await response.json();
                setConseils(data);
            } catch (error) {
                console.error('Error fetching conseils:', error);
            }
        };
        fetchConseils();
    };

    const handleDelete = () => {
        setConseils(conseils.filter(conseil => conseil.id !== selectedConseil.id));
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Conseils Anti-Gaspi</h1>
            {error ? (
                <p className="text-red-500">Erreur lors du chargement des conseils : {error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
                    {conseils.map((conseil) => (
                        <div key={conseil.id} className="bg-white shadow-lg rounded-lg p-4">
                            <h2 className="text-xl font-semibold">{conseil.titre}</h2>
                            <p className="text-sm text-gray-600">Auteur: {conseil.auteur}</p>
                            <p className="text-sm text-gray-600">Date: {new Date(conseil.date).toLocaleDateString()}</p>
                            <p className="mt-2 text-gray-800">{conseil.texte}</p>
                        
                            <button 
                                onClick={() => handleEditClick(conseil)}
                                className="block w-full py-2 mt-2 text-center rounded bg-green-500 text-white font-semibold hover:bg-green-600"
                            >
                                Modifier
                            </button>
                            <button 
                                onClick={() => handleDeleteClick(conseil)}
                                className="block w-full py-2 mt-2 text-center rounded bg-red-500 text-white font-semibold hover:bg-red-600"
                            >
                                Supprimer
                            </button>
                        </div>
                    ))}
                </div>
            )}
            
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"> 
                <Link to="/admin/creation-conseil-anti-gaspi">Créer un nouveau conseil</Link>
            </button>

            {showEditModal && (
                <EditConseilModal
                    conseil={selectedConseil}
                    onClose={() => setShowEditModal(false)}
                    onSave={handleSave}
                />
            )}

            {showDeleteModal && (
                <DeleteConseilModal
                    conseil={selectedConseil}
                    onClose={() => setShowDeleteModal(false)}
                    onDelete={handleDelete}
                />
            )}
        </div>
    );
};

export default ListeConseilAntiGaspi;
