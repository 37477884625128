import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    nom: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation côté client
    if (!formData.nom || !formData.email || !formData.message) {
      alert('Veuillez remplir tous les champs.');
      return;
    }

    // Envoi des données au backend
    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Message envoyé avec succès');
        // Réinitialiser le formulaire ou afficher un message de succès ici
      } else {
        console.error('Erreur lors de l\'envoi du message');
        // Gérer les erreurs spécifiques du backend ici
      }
    } catch (error) {
      console.error('Erreur de réseau', error);
    }
  };

  return (
    <section className="py-20 bg-[#F0F4E3] text-center">
      <div className="max-w-screen-md mx-auto px-4 bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-4xl font-bold text-[#B1702E] mb-10">Nous contacter</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="nom" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Nom</label>
            <input
              type="text"
              id="nom"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E]"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E]"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E]"
            ></textarea>
          </div>
          <button type="submit" className="bg-[#B1702E] text-white py-3 px-6 rounded-lg hover:bg-[#A15B1B] transition-colors">Envoyer</button>
        </form>
      </div>
    </section>
  );
}

export default ContactForm;
