import React, { useState } from 'react';

function ReservationCoursCuisineModal({ showModal, setShowModal, selectedClass, user, handleConfirmReservation }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleReservation = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('https://nomorewaste.fun/api/booking/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cookingClassId: selectedClass?.id, // Assurez-vous que selectedClass est défini
                    userId: user.id,
                }),
            });

            if (!response.ok) {
                const text = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
            }

            handleConfirmReservation();
        } catch (error) {
            console.error('Error during reservation:', error);
            setError('Failed to reserve a spot. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    if (!selectedClass) {
        return null; // Ne rien rendre si selectedClass est null
    }

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${showModal ? 'block' : 'hidden'}`}>
            <div className="bg-white p-8 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-4">Confirmer la réservation</h2>
                <p>Cours: {selectedClass.title}</p>
                <p>Bénévole: {selectedClass.volunteer?.name}</p>
                <p><strong>Durée:</strong> {selectedClass.duration} minutes</p>
                <h4 className="mt-4 text-large font-bold text-green-600">Détails de la réservation</h4>
                <p><strong>Utilisateur:</strong> {user.name}</p>
                <p><strong>Email:</strong> {user.email}</p>

                {error && <p className="text-red-500">{error}</p>}

                <div className="flex justify-end mt-4">
                    <button onClick={() => setShowModal(false)} className="px-4 py-2 bg-gray-500 text-white rounded">Annuler</button>
                    <button
                        onClick={handleReservation}
                        className={`ml-2 px-4 py-2 bg-green-500 text-white rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Réservation...' : 'Confirmer'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ReservationCoursCuisineModal;
