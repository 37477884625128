import React from 'react';
import { Link } from 'react-router-dom';

function CoursCuisine() {
  return (
    <div className="bg-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-8">
          Bienvenue à Nos Cours de Cuisine
        </h1>
        <p className="text-lg text-gray-700 text-center mb-12">
          Explorez notre collection de cours de cuisine pour apprendre à préparer des plats délicieux et raffinés. Que vous soyez débutant ou un chef expérimenté, nous avons des cours qui répondent à tous les niveaux.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          <div className="relative">
            <img 
              src="/cook.jpg" 
              alt="Delicious Dishhh" 
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50 rounded-lg shadow-md flex items-center justify-center">
              <span className="text-white text-xl font-semibold">Plats Gourmands</span>
            </div>
          </div>
          <div className="relative">
            <img 
              src="/1.jpg" 
              alt="Cuisine Art" 
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50 rounded-lg shadow-md flex items-center justify-center">
              <span className="text-white text-xl font-semibold">Cuisine Raffinée</span>
            </div>
          </div>
          <div className="relative">
            <img 
              src="/images/2.jpg" 
              alt="Cooking Recipe" 
              className="w-full h-64 object-cover rounded-lg shadow-md"
            />
            <div className="absolute inset-0 bg-gray-900 bg-opacity-50 rounded-lg shadow-md flex items-center justify-center">
              <span className="text-white text-xl font-semibold">Recettes Savoureuses</span>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link
            to="/user/cooking-classes"
            className="inline-block px-8 py-4 bg-green-500 text-white text-lg font-semibold rounded-lg shadow hover:bg-green-600 transition-colors"
          >
            Accéder à Nos Cours de Cuisine
          </Link>
        </div>
        <div className="text-center">
          <Link
            to="/user/create-cooking-class"
            className="inline-block px-8 py-4 bg-green-500 text-white text-lg font-semibold rounded-lg shadow hover:bg-green-600 transition-colors"
          >
            Candidatez pour un Cours de Cuisine
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CoursCuisine;