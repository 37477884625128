import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-[#E2CBAF] text-marron py-8">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center">
              <img src="/images/Logo.svg" className="w-20" alt="No More Waste Logo" />
            </Link>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-green-700 uppercase">Resources</h2>
              <ul className="text-marron">
                <li className="mb-4">
                  <Link to="/contact" className="hover:underline">Contact Us</Link>
                </li>
                <li className="mb-4">
                  <Link to="/about-us" className="hover:underline">À propos de nous</Link>
                </li>
                <li className="mb-4">
                  <Link to="/terms" className="hover:underline">Terms & Conditions</Link>
                </li>
                <li className="mb-4">
                  <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                </li>
                <li className="mb-4">
                  <Link to="/admin" className="hover:underline">Administrateur</Link>
                </li>

              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-green-700 uppercase">Contact</h2>
              <ul className="text-marron">
                <li className="mb-4">
                  <p className="hover:underline">Adresse : 26 Rue des Boucheries, 77500 Meaux</p>
                </li>
                <li className="mb-4">
                  <a href="mailto:contact@nomorewaste.fr" className="hover:underline">contact@nomorewaste.fr</a>
                </li>
                <li className="mb-4">
                  <a href="tel:+33612345678" className="hover:underline">+33 6 12 34 56 78</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-green-700 uppercase">Follow us</h2>
              <ul className="flex space-x-4">
                <li>
                  <a href="#" className="text-marron hover:text-green-700">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="text-marron hover:text-green-700">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="text-marron hover:text-green-700">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#" className="text-marron hover:text-green-700">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
