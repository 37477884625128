import React, { useState, useEffect } from 'react';
import ReservationCoursCuisineModal from './RéservationCoursCuisineModal';
import { useAuth } from '../../auth/AuthContext'; // Assurez-vous que le chemin est correct
import ModalStockCarousel from '../../admin/Stock/ModalStockCarousel';

function UserCookingClassList() {
    const [cookingClasses, setCookingClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [userReservations, setUserReservations] = useState([]);
    const { user } = useAuth(); // Assurez-vous que useAuth est bien importé et utilisé

    useEffect(() => {
        const fetchCookingClasses = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/cooking-classes');
                if (!response.ok) {
                    const text = await response.text();
                    throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                }
                const data = await response.json();
                setCookingClasses(data);
                console.log('Cooking classes fetched successfully:', data);
            } catch (error) {
                console.error('Error fetching cooking classes:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
    
        const fetchUserReservations = async () => {
            if (user && user.id) {
                try {
                    console.log(`Fetching reservations for user ID: ${user.id}`);
                    const response = await fetch(`https://nomorewaste.fun/api/booking/user-reservations/${user.id}`);
                    if (response.ok) {
                        const data = await response.json();
                        console.log('User reservations fetched successfully:', data);
                        setUserReservations(data.map(res => res.cookingClassId));
                    } else if (response.status === 404) {
                        console.log('No bookings found for this user.');
                        setUserReservations([]);
                    } else {
                        const text = await response.text();
                        throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
                    }
                } catch (error) {
                    console.error('Error fetching user reservations:', error);
                    setError(error.message);
                }
            } else {
                console.log('User not logged in or user ID is missing');
            }
        };
    
        fetchCookingClasses();
        fetchUserReservations();
    }, [user]);

    const handleReserve = (cookingClass) => {
        if (!user || !cookingClass) {
            console.error("User or Cooking Class is null");
            return;
        }

        setSelectedClass(cookingClass);
        setShowModal(true);
    };

    const handleCancelReservation = async (cookingClassId) => {
        if (!user || !cookingClassId) {
            console.error("User or Cooking Class ID is null");
            return;
        }

        try {
            const response = await fetch('https://nomorewaste.fun/api/booking/cancel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cookingClassId,
                    userId: user.id,
                }),
            });

            if (!response.ok) {
                const text = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
            }

            console.log('Reservation cancelled for class ID:', cookingClassId);
            setUserReservations(userReservations.filter(id => id !== cookingClassId));
        } catch (error) {
            console.error('Error during cancellation:', error);
        }
    };

    const handleConfirmReservation = () => {
        console.log('Reservation confirmed for:', selectedClass);
        setUserReservations([...userReservations, selectedClass.id]);
        setShowModal(false);
    };

    if (loading) {
        return <p className="text-center text-gray-500">Loading cooking classes...</p>;
    }

    if (error && error !== 'No bookings found for this user.') {
        return <p className="text-center text-red-500">Error loading cooking classes: {error}</p>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Nos Cours de Cuisine</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {cookingClasses.length > 0 ? (
                    cookingClasses
                        .filter(cookingClass => cookingClass.isValidated) // Filtrer les cours validés
                        .map((cookingClass) => (
                            <div key={cookingClass.id} className="bg-white shadow-lg rounded-lg overflow-hidden">
                                <img src="/images/3.jpg" alt="Cooking Class" className="w-full h-48 object-cover" />
    
                                <div className="p-6">
                                    <h2 className="text-xl font-semibold mb-2">{cookingClass.title}</h2>
                                    <p className="text-gray-700 mb-4">{cookingClass.description}</p>
                                    <p className="text-sm text-gray-600">Durée: {cookingClass.duration} minutes</p>
                                    <p className="text-sm text-gray-600">Heure de début: {new Date(cookingClass.startTime).toLocaleString()}</p>
                                    <p className="text-sm text-gray-600">Bénévole: {cookingClass.volunteer?.name}</p>
                                    <p className="text-sm text-gray-600 font-bold mt-2">Places disponibles: {cookingClass.availableSlots}</p>
                                </div>
                                {userReservations.includes(cookingClass.id) ? (
                                    <button
                                        onClick={() => handleCancelReservation(cookingClass.id)}
                                        className="block w-full py-2 bg-red-500 text-white font-semibold hover:bg-red-600 transition-colors"
                                    >
                                        Annuler la réservation
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => handleReserve(cookingClass)}
                                        className="block w-full py-2 bg-green-500 text-white font-semibold hover:bg-green-600 transition-colors"
                                    >
                                        Réserver une place
                                    </button>
                                )}
                            </div>
                        ))
                ) : (
                    <p className="col-span-full text-center text-gray-500">Aucun cours de cuisine programmés, revenez plus tard 📆.</p>
                )}
            </div>
            <ModalStockCarousel />
            {/* Modal de réservation */}
            {showModal && selectedClass && (
                <ReservationCoursCuisineModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    selectedClass={selectedClass}
                    user={user}
                    handleConfirmReservation={handleConfirmReservation}
                />
            )}
        </div>
    );
}    
export default UserCookingClassList;
