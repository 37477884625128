import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext'; // Assurez-vous que le chemin est correct

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuth();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <nav className="bg-[#E2CBAF]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link 
              to="/" 
              className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Home
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4">
              {/* Affiche Blog, À propos de nous, et Contact seulement si l'utilisateur n'est pas connecté */}
              {!user && (
                <>
                  <Link to="/Blog" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Blog</Link>
                  <Link to="/about-us" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">À propos de nous</Link>
                  <Link to="/contact" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Contact</Link>
                </>
              )}

              <Link to="/user/conseil-anti-gaspi" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Conseil-AntiGaspi</Link>

              {/* Contenu pour les utilisateurs connectés */}
              {user && (
                <>
                  {/* Admin has access to everything */}
                  {user.user_type === 'admin' && (
                    <>
                      <Link to="/distributions" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Distributions</Link>
                      <Link to="/admin/dons" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Dons</Link>
                      <Link to="/admin" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Admin Dashboard</Link>
                    </>
                  )}

                  {/* Menu pour les commercants */}
                  {user.user_type === 'commercant' && (
                    <>
                      <Link to="/distributions" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Distributions</Link>
                      <Link to="/admin/dons" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Dons</Link>
                    </>
                  )}

                  {/* Menu pour les bénévoles */}
                  {user.user_type === 'benevole' && (
                    <>
                      <Link to="/calendrier" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Calendrier</Link>
                      <Link to="user/service" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Service</Link>
                    </>
                  )}

                  {/* Liens communs pour tous les utilisateurs connectés */}
                  <Link to="/user/profile" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Mon Profil</Link>
                  <Link to="/user/courses" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Cours de cuisine</Link>
                  <button
                    onClick={handleLogout}
                    className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Déconnexion
                  </button>
                  <span className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                    👤 {/* Icon représentant l'utilisateur */}
                  </span>
                </>
              )}

              {/* Liens pour les utilisateurs non connectés */}
              {!user && (
                <>
                  <Link to="/connexion" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Connexion</Link>
                  <Link to="/inscription" className="text-green-700 hover:bg-green-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Inscription</Link>
                </>
              )}
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="bg-green-700 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link 
              to="/" 
              className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </Link>

            {!user && (
              <>
                <Link to="/about-us" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">À propos de nous</Link>
                <Link to="/contact" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Contact</Link>
              </>
            )}

            {user && (
              <>
                {user.user_type === 'admin' && (
                  <>
                    <Link to="/distributions" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Distributions</Link>
                    <Link to="/admin/dons" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Dons</Link>
                    <Link to="/admin" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Admin Dashboard</Link>
                  </>
                )}

                {user.user_type === 'commercant' && (
                  <>
                    <Link to="/distributions" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Distributions</Link>
                    <Link to="/admin/dons" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Dons</Link>
                  </>
                )}

                {user.user_type === 'benevole' && (
                  <>
                    <Link to="/calendrier" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendrier</Link>
                    <Link to="/services" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Service</Link>
                  </>
                )}

                {/* Liens communs pour tous les utilisateurs connectés */}
                <Link to="/user/profile" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Mon Profil</Link>
                <Link to="/user/courses" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Cours de cuisine</Link>
                <button
                  onClick={handleLogout}
                  className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Déconnexion
                </button>
              </>
            )}

            {!user && (
              <>
                <Link to="/connexion" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Connexion</Link>
                <Link to="/inscription" className="text-green-700 hover:bg-green-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Inscription</Link>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
