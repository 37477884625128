import React from 'react';

function AboutUs() {
  return (
    <section className="bg-gray-100 py-16">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center text-brown-800">À propos de nous</h2>
        <p className="text-gray-700 mt-4 text-lg text-center">
          Chez Nomorewaste, nous sommes dédiés à la lutte contre le gaspillage alimentaire. Depuis notre création, notre mission a toujours été de sauver de la nourriture tout en aidant ceux qui en ont le plus besoin. 
          Nous croyons fermement que chaque geste compte et que, ensemble, nous pouvons réduire significativement le gaspillage alimentaire dans notre société.
        </p>
        
        {/* Image: Tournée de Ramassage */}
        <div className="mt-8 mb-16">
          <div className="w-full h-96 bg-cover bg-center rounded-lg shadow-lg" style={{ backgroundImage: "url('/images/tournée.png')" }}></div>
          <p className="text-center text-gray-600 mt-4">Nos tournées de ramassage sont au cœur de nos opérations, permettant de récupérer les surplus alimentaires avant qu'ils ne soient jetés.</p>
        </div>
        
        <div className="mt-8">
          <h3 className="text-2xl font-semibold text-brown-800">Notre Mission</h3>
          <p className="text-gray-700 mt-4">
            La mission de Nomorewaste est de créer un monde où la nourriture est valorisée et où chaque repas compte. Nous travaillons chaque jour pour réduire le gaspillage alimentaire à travers diverses initiatives, telles que les collectes de surplus, les dons alimentaires, et les partenariats avec des entreprises locales. 
            Nous croyons que l'éducation est essentielle pour changer les comportements, c'est pourquoi nous investissons également dans des campagnes de sensibilisation et des ateliers éducatifs.
          </p>
          <p className="text-gray-700 mt-4">
            Grâce à nos efforts et au soutien de notre communauté, nous avons pu redistribuer des milliers de repas à des familles dans le besoin, tout en réduisant les déchets alimentaires. Chaque jour, notre équipe se consacre à cette mission avec passion et détermination.
          </p>
        </div>

        {/* Image: L'équipe */}
        <div className="mt-8 mb-16">
          <div className="w-full h-96 bg-cover bg-center rounded-lg shadow-lg" style={{ backgroundImage: "url('/images/team.png')" }}></div>
          <p className="text-center text-gray-600 mt-4">Notre équipe dévouée travaille sans relâche pour faire de notre mission une réalité.</p>
        </div>
        
        <div className="mt-8">
          <h3 className="text-2xl font-semibold text-brown-800">Nos Valeurs</h3>
          <ul className="list-disc list-inside text-gray-700 mt-4">
            <li><strong>Solidarité :</strong> Nous croyons en l'entraide et au soutien mutuel. Nos actions sont guidées par le désir d'aider les autres.</li>
            <li><strong>Durabilité :</strong> Nous œuvrons pour un avenir durable en réduisant les déchets et en promouvant des pratiques respectueuses de l'environnement.</li>
            <li><strong>Innovation :</strong> Nous sommes constamment à la recherche de nouvelles façons de réduire le gaspillage alimentaire et d'améliorer nos processus.</li>
            <li><strong>Transparence :</strong> Nous sommes ouverts et honnêtes dans toutes nos interactions, avec notre équipe, nos partenaires, et la communauté.</li>
          </ul>
          <p className="text-gray-700 mt-4">
            Ces valeurs sont au cœur de tout ce que nous faisons, et elles guident chaque décision que nous prenons. Nous sommes convaincus que, par notre travail, nous pouvons inspirer d'autres à adopter des pratiques plus durables et à rejoindre notre lutte contre le gaspillage alimentaire.
          </p>
        </div>

        <div className="mt-8">
          <h3 className="text-2xl font-semibold text-brown-800">Notre Histoire</h3>
          <p className="text-gray-700 mt-4">
            Nomorewaste a été fondé avec une vision simple : réduire le gaspillage alimentaire tout en aidant les plus démunis. Ce qui a commencé comme un petit projet local s'est rapidement transformé en une initiative à grande échelle, touchant des milliers de vies chaque année. 
            Nous avons grandi grâce à notre communauté de bénévoles, de partenaires, et de supporters, tous unis par la même conviction que chaque repas compte.
          </p>
          <p className="text-gray-700 mt-4">
            Aujourd'hui, Nomorewaste continue de croître et d'évoluer, mais notre mission reste la même. Nous sommes fiers du chemin parcouru et excités par les opportunités futures pour faire encore plus de bien.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
