import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext'; // Assurez-vous que le chemin est correct

function Connexion() {
  const [loginData, setLoginData] = useState({
    email: '',
    mot_de_passe: '',
  });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth(); // Récupérer la fonction `login` du contexte

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!loginData.email || !loginData.mot_de_passe) {
        setMessage('Veuillez remplir tous les champs.');
        return;
    }

    try {
        const response = await fetch('https://nomorewaste.fun/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        });

        const text = await response.text();
        console.log('Server response:', text); // Affichez la réponse complète du serveur

        if (response.ok) {
            try {
                const data = JSON.parse(text);
                console.log('Parsed data:', data);

                if (data && data.user) {
                    login(data.user); // Assurez-vous que `data.user` existe
                    setMessage('Connexion réussie ! Redirection en cours...');
        
                    setTimeout(() => {
                        navigate('/');
                    }, 3000);
                } else {
                    console.error('User data is undefined:', data);
                    setMessage('Erreur lors de la connexion : Données utilisateur manquantes.');
                }
            } catch (parseError) {
                console.error('Error parsing JSON:', parseError);
                setMessage('Erreur lors de la connexion : Problème de format des données reçues.');
            }
        } else {
            setMessage('Erreur lors de la connexion : ' + (text || 'Erreur inconnue.'));
        }
    } catch (error) {
        console.error('Error:', error);
        setMessage('Erreur de réseau. Veuillez réessayer plus tard.');
    }
};


  return (
    <section className="py-20 bg-[#F0F4E3]">
      <div className="max-w-md mx-auto px-4 bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-[#B1702E] mb-8">Connexion</h2>
        {message && (
          <div className={`text-sm mb-4 ${message.includes('réussie') ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="email" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={loginData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E] shadow-sm"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="mot_de_passe" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Mot de passe</label>
            <input
              type="password"
              id="mot_de_passe"
              name="mot_de_passe"
              value={loginData.mot_de_passe}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E] shadow-sm"
            />
          </div>
          <button type="submit" className="bg-[#B1702E] text-white py-3 px-6 rounded-lg hover:bg-[#A15B1B] transition-colors">Se connecter</button>
        </form>
      </div>
    </section>
  );
}

export default Connexion;
