// FRONT/app/src/admin/Dons/CreationStock.js

import React, { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {user} from '../../auth/AuthContext';

const CreationStock = () => {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [unit, setUnit] = useState('');
    const [provenance, setProvenance] = useState('');
    const [dateAjout, setDateAjout] = useState('');
    const [commercants, setCommercants] = useState([]); // Nouvel état pour les commercants
    const [selectedCommercant, setSelectedCommercant] = useState(''); // Pour stocker l'ID du commercant sélectionné
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCommercants = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/commercants');
                const data = await response.json();
                setCommercants(data);
            } catch (error) {
                console.error('Error fetching commercants:', error);
            }
        };

        fetchCommercants();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const stockData = {
            name,
            quantity,
            category,
            subCategory,
            unit,
            provenance,
            dateAjout,
            user_id: selectedCommercant, // Ajout de l'ID du commercant sélectionné
        };

        try {
            const response = await fetch('https://nomorewaste.fun/api/stocks', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stockData),
            });

            if (response.ok) {
                setMessage('Produit ajouté avec succès !');
                setName('');
                setQuantity('');
                setCategory('');
                setSubCategory('');
                setUnit('');
                setProvenance('');
                setDateAjout('');
                setSelectedCommercant(''); // Réinitialiser la sélection du commercant
                navigate('/admin/stocks');
            } else {
                setMessage('Erreur lors de l\'ajout du produit.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Erreur lors de l\'ajout du produit.');
        }
    };


    return (
        <div className="max-w-4xl mx-auto py-10">
            <h1 className="text-3xl font-bold mb-6">Ajouter un Produit au Stock</h1>
            {message && <p className="mb-4 text-green-500">{message}</p>}
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Nom du Produit
                    </label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Nom du produit"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quantity">
                        Quantité
                    </label>
                    <input
                        id="quantity"
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Quantité"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                        Catégorie
                    </label>
                    <select
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    >
                        <option value="">Sélectionnez une catégorie</option>
                        <option value="Frais">Produits Frais</option>
                        <option value="Légumes">Légumes</option>
                        <option value="Viandes">Viandes</option>
                        <option value="Fruits">Fruits</option>
                        <option value="Boissons">Boissons</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subCategory">
                        Sous-catégorie
                    </label>
                    <input
                        id="subCategory"
                        type="text"
                        value={subCategory}
                        onChange={(e) => setSubCategory(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Sous-catégorie du produit"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="unit">
                        Unité
                    </label>
                    <input
                        id="unit"
                        type="text"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Unité (ex: kg, litres, unités)"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="provenance">
                        Provenance
                    </label>
                    <input
                        id="provenance"
                        type="text"
                        value={provenance}
                        onChange={(e) => setProvenance(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Provenance du produit"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateAjout">
                        Date d'Ajout
                    </label>
                    <input
                        id="dateAjout"
                        type="date"
                        value={dateAjout}
                        onChange={(e) => setDateAjout(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="commercant">
                        Commerçant
                    </label>
                    <select
                        id="commercant"
                        value={selectedCommercant}
                        onChange={(e) => setSelectedCommercant(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    >
                        <option value="">Sélectionnez un commerçant</option>
                        {commercants.map((commercant) => (
                            <option key={commercant.id} value={commercant.id}>
                                {commercant.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Ajouter le Produit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreationStock;
