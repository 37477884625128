import React from 'react';

function Blog() {
  return (
    <div className="bg-beige-100 py-8">
      <div className="max-w-screen-lg mx-auto px-4">
        <h1 className="text-4xl font-bold text-center text-khaki-700 mb-8">Blog - Nos Services</h1>

        {/* Article 1 */}
        <article className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="h-64 bg-cover bg-center" style={{ backgroundImage: "url('/images/AntiGaspi.png')" }}></div>
          <div className="p-6">
            <h2 className="text-2xl font-semibold text-brown-800">Réduction du Gaspillage Alimentaire</h2>
            <p className="text-gray-700 mt-4">
              Chez Nomorewaste, nous sommes passionnés par la réduction du gaspillage alimentaire. 
              Saviez-vous qu'un tiers de la nourriture produite chaque année est gaspillée ? 
              Pour combattre ce fléau, nous avons mis en place des stratégies efficaces pour sauver des tonnes de nourriture de la décharge.
              Nous travaillons avec des agriculteurs, des supermarchés et des restaurants pour récupérer les excédents alimentaires et les redistribuer à ceux qui en ont besoin.
              Notre programme inclut également des ateliers de cuisine anti-gaspillage, où nous enseignons aux participants comment utiliser les restes de manière créative.
            </p>
            <p className="text-gray-700 mt-4">
              Notre objectif est de sensibiliser le public et de promouvoir des habitudes alimentaires durables qui réduisent les déchets tout en nourrissant ceux qui sont dans le besoin. 
              Chaque geste compte, et ensemble, nous pouvons faire une différence significative dans la lutte contre le gaspillage alimentaire.
            </p>
          </div>
        </article>

        {/* Article 2 */}
        <article className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="h-64 bg-cover bg-center" style={{ backgroundImage: "url('/images/Cover.jpg')" }}></div>
          <div className="p-6">
            <h2 className="text-2xl font-semibold text-brown-800">Partenariats avec les Commerces Locaux</h2>
            <p className="text-gray-700 mt-4">
              Nous croyons fermement en la collaboration avec les commerces locaux pour réduire le gaspillage alimentaire. 
              Les petits commerçants, épiceries et restaurants jouent un rôle crucial dans notre mission. 
              En travaillant ensemble, nous récupérons les surplus alimentaires avant qu'ils ne soient jetés et les redistribuons à des associations caritatives.
              Nos partenaires bénéficient également d'une réduction des coûts liés à la gestion des déchets et d'une meilleure image de marque en tant qu'entreprises responsables.
            </p>
            <p className="text-gray-700 mt-4">
              Nous avons établi des relations solides avec de nombreux commerces locaux, qui partagent notre vision d'une société sans gaspillage. 
              Ces collaborations nous permettent d'étendre notre impact et de faire en sorte que plus de nourriture soit consommée plutôt que gaspillée. 
              Ensemble, nous bâtissons une communauté plus durable et plus solidaire.
            </p>
          </div>
        </article>

        {/* Article 3 */}
        <article className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="h-64 bg-cover bg-center" style={{ backgroundImage: "url('/images/rc.png')" }}></div>
          <div className="p-6">
            <h2 className="text-2xl font-semibold text-brown-800">Éducation et Sensibilisation</h2>
            <p className="text-gray-700 mt-4">
              L'éducation est au cœur de notre mission chez Nomorewaste. Nous croyons que pour réduire véritablement le gaspillage alimentaire, il est essentiel de sensibiliser et d'éduquer le public. 
              Nous organisons régulièrement des ateliers, des conférences et des événements communautaires pour informer les gens sur l'ampleur du problème du gaspillage alimentaire et leur montrer comment ils peuvent contribuer à sa réduction.
            </p>
            <p className="text-gray-700 mt-4">
              Nos programmes éducatifs couvrent divers sujets, allant des techniques de conservation des aliments à la planification des repas, en passant par la réutilisation créative des restes.
              Nous travaillons également avec des écoles et des universités pour intégrer des cours sur la durabilité et la lutte contre le gaspillage alimentaire dans leurs programmes.
              En sensibilisant la prochaine génération, nous jetons les bases d'une culture où la nourriture est valorisée et où le gaspillage est minimisé.
            </p>
          </div>
        </article>

        {/* Article 4 */}
        <article className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="h-64 bg-cover bg-center" style={{ backgroundImage: "url('/images/rec.png')" }}></div>
          <div className="p-6">
            <h2 className="text-2xl font-semibold text-brown-800">Application Mobile Nomorewaste</h2>
            <p className="text-gray-700 mt-4">
              La technologie au service de la durabilité ! Avec notre application mobile, nous aidons les utilisateurs à suivre leur consommation alimentaire et à réduire leur gaspillage.
              L'application Nomorewaste propose des fonctionnalités telles que la gestion de l'inventaire de votre cuisine, des recettes anti-gaspillage, et des rappels pour consommer les aliments avant qu'ils ne se gâtent.
              Vous pouvez également suivre votre impact en matière de réduction du gaspillage et partager vos réussites avec la communauté.
            </p>
            <p className="text-gray-700 mt-4">
              En utilisant notre application, non seulement vous contribuez à réduire le gaspillage alimentaire, mais vous économisez également de l'argent. 
              De plus, l'application vous met en relation avec des commerces locaux proposant des produits à prix réduits, qui seraient autrement gaspillés.
              Rejoignez des milliers d'utilisateurs qui ont déjà fait le choix d'une consommation plus responsable grâce à l'application Nomorewaste.
            </p>
          </div>
        </article>

         {/* Article 5 */}
         <article className="bg-white shadow-lg rounded-lg overflow-hidden mb-8 flex flex-col md:flex-row">
          <div className="md:w-1/2 h-auto flex-grow">
            <img src="/images/don.jpg" alt="Programmes de Don Alimentaire" className="w-full h-full object-cover" />
          </div>
          <div className="p-6 md:w-1/2">
            <h2 className="text-2xl font-semibold text-brown-800">Programmes de Don Alimentaire</h2>
            <p className="text-gray-700 mt-4">
              Chez Nomorewaste, nous croyons que la nourriture est une ressource précieuse qui ne devrait jamais être gaspillée. 
              C'est pourquoi nous avons mis en place des programmes de don alimentaire qui permettent de redistribuer les excédents alimentaires à ceux qui en ont le plus besoin.
              Nos partenariats avec des banques alimentaires, des associations caritatives et d'autres organisations communautaires assurent que la nourriture excédentaire atteint les personnes en situation d'insécurité alimentaire.
            </p>
            <p className="text-gray-700 mt-4">
              Nous facilitons également le processus de don pour les entreprises, en leur fournissant une logistique et un support simplifiés. 
              Grâce à nos efforts, des milliers de repas sont distribués chaque mois à travers le pays.
              En participant à nos programmes de don alimentaire, vous faites une différence tangible dans la lutte contre la faim et le gaspillage alimentaire.
            </p>
          </div>
        </article>
      </div>
    </div>
  );
}

export default Blog;
