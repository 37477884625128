import React from 'react';
import { Link } from 'react-router-dom';

const AdminDashBoard = () => {
  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-gray-800 text-white flex flex-col p-6">
        <h2 className="text-2xl font-bold mb-8">Admin Dashboardddd</h2>
        <nav>
          <ul>
            <li className="mb-4">
          
              <Link to="/admin/adhesions" className="text-white hover:text-gray-300">Gestion des Adhésions</Link>

            </li>
            <li className="mb-4">
             
              <Link to="/admin/distributions" className="text-white hover:text-gray-300">Gestion des distributions</Link>
              
            </li>
            <li className="mb-4">

             < Link to="/admin/stocks" className="text-white hover:text-gray-300">Gestion des Stocks</Link>
  
              </li>
            <li className="mb-4">
              <Link to="/admin/tournees" className="text-white hover:text-gray-300">Gestion des Tournées</Link>
            </li>
            <li className="mb-4">
              <Link to="/admin/gestion-benevole" className="text-white hover:text-gray-300">Gestion des Bénévoles</Link>
            </li>
            <li className="mb-4">
              <Link to="/admin/liste-conseil-anti-gaspi" className="text-white hover:text-gray-300">Conseils Anti-Gaspillage</Link>
            </li>
            <li className="mb-4">
             <Link to="/admin/cooking-class" className="text-white hover:text-gray-300">Cours de Cuisine</Link>
            </li>
            <li className="mb-4">
            <Link to="/admin/gestion-services" className="text-white hover:text-gray-300">Gestion Des Services</Link>
            </li>
           
            
          </ul>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 p-10">
        <h1 className="text-4xl font-bold mb-10 text-gray-800">Tableau de Bord Administrateur</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Gestion des Adhésions</h2>
            <p className="text-gray-600 mb-4">Gérer les adhésions des commerçants, y compris les rappels de renouvellement.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Adhésions</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Gestion des Distributions</h2>
            <p className="text-gray-600 mb-4">Gérer les Distributions de nourriture.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Collectes</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Gestion des Stocks</h2>
            <p className="text-gray-600 mb-4">Gérer les stocks des produits collectés.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Stocks</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Gestion des Tournées</h2>
            <p className="text-gray-600 mb-4">Gérer les tournées de distribution des produits.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Tournées</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Gestion des Bénévoles</h2>
            <p className="text-gray-600 mb-4">Gérer les bénévoles, y compris les inscriptions et la gestion des capacités.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Bénévoles</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Conseils Anti-Gaspillage</h2>
            <p className="text-gray-600 mb-4">Intégrer et gérer les articles et conseils anti-gaspillage.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Conseils
              <Link to="/admin/creation-conseil-anti-gaspi" className="text-white hover:text-gray-300"></Link>
            </button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Cours de Cuisine</h2>
            <p className="text-gray-600 mb-4">Gérer l'inscription en ligne aux cours de cuisine, y compris les rappels.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Cours de Cuisine</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Partage de Véhicules</h2>
            <p className="text-gray-600 mb-4">Organiser et gérer le partage de véhicules entre adhérents.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Partages de Véhicules</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Échange de Services</h2>
            <p className="text-gray-600 mb-4">Mettre en relation les adhérents pour l'échange de services (bricolage, électricité, etc.).</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Échanges de Services</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Services de Réparation</h2>
            <p className="text-gray-600 mb-4">Gérer les réservations pour les services de réparation proposés par l'association.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Services de Réparation</button>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Gardiennage</h2>
            <p className="text-gray-600 mb-4">Gérer l'inscription et les demandes de gardiennage.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Voir les Demandes de Gardiennage</button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default AdminDashBoard;
