import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import Profile from './user/Profile';
import Connexion from './pages/Connexion';
import Inscription from './pages/Inscription';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminDashBoard from './admin/AdminDashboard';
import AuditAntiGaspiForm from './pages/AuditAntiGaspiForm';
import ListeConseilAntiGaspi from './admin/ConseilAntiGaspi/ListeConseilAntiGaspi';
import CreationConseilAntiGaspi from './admin/ConseilAntiGaspi/CreationConseilAntiGaspi';
import Error404 from './pages/Error404';
import CookingClassDetail from './admin/CookingClass/CookingClassDetail';
import CookingClassList from './admin/CookingClass/CookingClassList';
import ResaCookingClass from './admin/CookingClass/ResaCookingClass';
import CreateCookingClass from './admin/CookingClass/CreateCookingClass';
import Adhesion from './admin/adhesions/Adhesion'; 
import ListeAdhesions from './admin/adhesions/ListeAdhesions';
import CreationAdhesion from './admin/adhesions/CreationAdhesion';
import GestionStocks from './admin/Stock/GestionStocks';
import CreationStock from './admin/Stock/CreationStock';
import Don from './admin/Dons/Don';
import DonationForm from './admin/Dons/DonationForm';
import Blog from './pages/Blog';
import CoursCuisine from './pages/CookingClass/CoursCuisine';
import Service from './pages/Service/Service';
import ReservationService from './pages/Service/ReservationService';
import CreateService from './pages/Service/CreateService';
import UserCookingClassList from './pages/CookingClass/UserCookingClassList';
import { AuthProvider, useAuth } from './auth/AuthContext';
import InscriptionTournee from './admin/tournee/InscriptionTournee';
import TourneeList from './admin/tournee/TourneeList';
import TourneeForm from './admin/tournee/TourneeForm';
import TourneeClient from './admin/tournee/TourneeClient';
import ConseilAntiGaspiList from './pages/ConseilAntiGaspi/ConseilAntiGaspiList';
import UserCreateCookingClass from './pages/CookingClass/CreateCookingClass';
import Distributions from './admin/distribution/Distribution'; 
import DistributionList from './admin/distribution/DistributionList';
import DistributionForm from './admin/distribution/DistributionForm';
import Calendrier from './pages/ServiceCalendar/Calendrier';
import ServiceCalendar from './pages/ServiceCalendar/ServiceCalendar'; 
import GestionServices from './admin/GestionServices/GestionServices';
import GestionBenevoles from './admin/GestionBenevole/GestionBenevoles';





function App() {
  const { user } = useAuth();

  useEffect(() => {
    console.log('User is logged in:', user);
  }, [user]);

  // Helper function to protect admin routes
  const requireAdmin = (component) => {
    if (user && user.user_type === 'admin') {
      return component;
    } else {
      return <Navigate to="/unhautorized" />;
    }
  };

  return (
    <Router>
      <AuthProvider>
        <Header /> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/connexion" element={<Connexion />} />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/distributions" element={<Distributions />} /> 
          <Route path="/unauthorized" element={<Error404 />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Auditantigaspi" element={<AuditAntiGaspiForm />} />

          {/* Routes Utilisateurs */}
          <Route path="/user/courses" element={<CoursCuisine />} />
          <Route path="/user/profile" element={<Profile></Profile>} />
          <Route path="/user/create-cooking-class" element={<UserCreateCookingClass />} />
          <Route path="/user/cooking-classes" element={<UserCookingClassList />} />
          <Route path="/user/service" element={<Service />} />
          <Route path="/user/service/reservation-service" element={<ReservationService />} />
          <Route path="/user/service/create-service" element={<CreateService />} />
          <Route path="/user/calendrier" element={<Calendrier />} />
          
          <Route path="/user/conseil-anti-gaspi" element={<ConseilAntiGaspiList />} />


          {/* Routes Admin protégées */}
          <Route path="/admin" element={requireAdmin(<AdminDashBoard />)} />
          <Route path="/admin/creation-conseil-anti-gaspi" element={requireAdmin(<CreationConseilAntiGaspi />)} />
          <Route path="/admin/liste-conseil-anti-gaspi" element={requireAdmin(<ListeConseilAntiGaspi />)} />
          <Route path="/admin/adhesions" element={requireAdmin(<Adhesion />)} />
          <Route path="/admin/liste-adhesions" element={requireAdmin(<ListeAdhesions />)} />
          <Route path="/admin/creation-adhesion" element={requireAdmin(<CreationAdhesion />)} />
          <Route path="/admin/cooking-class" element={requireAdmin(<CookingClassList />)} />
          <Route path="/admin/cooking-class/:id" element={requireAdmin(<CookingClassDetail />)} />
          <Route path="/admin/cooking-class/:id/bookings" element={requireAdmin(<ResaCookingClass />)} />
          <Route path="/admin/create-cooking-class" element={requireAdmin(<CreateCookingClass />)} />
          <Route path="/admin/stocks" element={requireAdmin(<GestionStocks />)} />
          <Route path="/admin/CreationStock" element={requireAdmin(<CreationStock />)} />
          <Route path="/admin/dons" element={requireAdmin(<Don />)} />
          <Route path="/admin/dons/make-donation" element={requireAdmin(<DonationForm />)} />
          <Route path="/admin/tournees" element={requireAdmin(<TourneeList />)} />
          <Route path="/admin/tournees/client" element={requireAdmin(<TourneeClient />)} /> 
          <Route path="/admin/tournees/new" element={requireAdmin(<TourneeForm />)} />
          <Route path="/admin/tournees/inscription/:id" element={requireAdmin(<InscriptionTournee />)} />
          <Route path="/admin/distributions" element={requireAdmin(<DistributionList />)} />
          <Route path="/admin/distributions/new" element={requireAdmin(<DistributionForm />)} />
          <Route path="/admin/distributions/edit/:id" element={requireAdmin(<DistributionForm />)} />
          <Route path="/admin/gestion-benevole" element={requireAdmin(<GestionBenevoles />)} />
          <Route path="/admin/gestion-services" element={requireAdmin(<GestionServices />)} />
          {/* Page 404 */}
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
