import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

const Distributions = () => {
    const [distributions, setDistributions] = useState([]);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Récupère les distributions programmées
        const fetchDistributions = async () => {
            try {
                const response = await fetch('/api/distributions');
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des distributions');
                }
                const data = await response.json();
                setDistributions(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchDistributions();
    }, []);

    const handleInscription = async (distributionId) => {
        // Redirige l'utilisateur vers la connexion s'il n'est pas connecté
        if (!user || !user.id) {
            navigate('/connexion');
            return;
        }

        // Données d'inscription pour l'utilisateur
        const inscriptionData = {
            nom: user.name,
            email: user.email,
            telephone: user.telephone,
            distributionId
        };

        try {
            const response = await fetch('/api/distribution/inscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(inscriptionData),
            });

            if (response.ok) {
                alert('Inscription réussie !');
            } else {
                const errorData = await response.json();
                alert(`Erreur lors de l'inscription: ${errorData.error || 'Une erreur est survenue.'}`);
            }
        } catch (err) {
            alert('Erreur lors de l\'inscription');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Distributions Programmées</h1>
            {error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {distributions.map(distribution => (
                        <div key={distribution.id} className="bg-white shadow-lg rounded-lg p-4">
                            <h2 className="text-xl font-semibold mb-2">{distribution.lieu}</h2>
                            <p className="text-gray-600 mb-2">
                                Date : {new Date(distribution.date).toLocaleDateString()}
                            </p>
                            <p className="text-gray-600 mb-2">
                                Capacité : {distribution.nombreInscrits}/{distribution.capaciteMaximale}
                            </p>
                            <button
                                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                onClick={() => handleInscription(distribution.id)}
                            >
                                S'inscrire
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Distributions;
