import React, { useState, useEffect } from 'react';
import CookingClassForm from './CookingClassForm';
import { Link } from 'react-router-dom';

function CreateCookingClass() {
    const [volunteers, setVolunteers] = useState([]);
    const [cookingClassData, setCookingClassData] = useState({
        title: '',
        description: '',
        startTime: '',
        duration: '',
        maxParticipants: '',
        volunteerId: '',
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchVolunteers = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/volunteers');
                const data = await response.json();
                setVolunteers(data);
            } catch (error) {
                console.error('Error fetching volunteers:', error);
            }
        };

        fetchVolunteers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCookingClassData({
            ...cookingClassData,
            [name]: value.trim(),  // Trim the input value to remove any leading/trailing spaces
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Trim all string inputs before sending the data
        const trimmedData = {
            ...cookingClassData,
            title: cookingClassData.title.trim(),
            description: cookingClassData.description.trim(),
            startTime: cookingClassData.startTime.trim(),
            duration: cookingClassData.duration.trim(),
            maxParticipants: cookingClassData.maxParticipants.trim(),
            volunteerId: cookingClassData.volunteerId.trim(),
        };

        try {
            const response = await fetch('https://nomorewaste.fun/api/create-cooking-class', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(trimmedData),
            });

            if (response.ok) {
                setMessage('Cooking class created successfully!');
                // Reset form fields
                setCookingClassData({
                    title: '',
                    description: '',
                    startTime: '',
                    duration: '',
                    maxParticipants: '',
                    volunteerId: '',
                });
            } else {
                setMessage('Failed to create cooking class.');
            }
        } catch (error) {
            console.error('Error creating cooking class:', error);
            setMessage('An error occurred while creating the cooking class.');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Create a New Cooking Class</h1>
            {message && <p className="mb-4 text-center">{message}</p>}
            <CookingClassForm
                cookingClassData={cookingClassData}
                volunteers={volunteers}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
            <button className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-150">
                <Link to={'/admin/cooking-class'}>Back to Cooking Classes</Link>
            </button>
        </div>
    );
}

export default CreateCookingClass;
