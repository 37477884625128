import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth/AuthContext';


function ReservationService() {
    const { user } = useAuth(); 
    const [services, setServices] = useState([]);
    const [myServices, setMyServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        async function fetchServices() {
            try {
                const response = await fetch('https://nomorewaste.fun/api/benevole/services');
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des services.');
                }
                const data = await response.json();
                setServices(data);
            } catch (err) {
                console.error(err);
                setError('Erreur lors de la récupération des services.');
            }
        }

        async function fetchMyServices() {
            try {
                const response = await fetch('https://nomorewaste.fun/api/benevole/mes-services');
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération de vos services.');
                }
                const data = await response.json();
                setMyServices(data);
            } catch (err) {
                console.error(err);
                setError('Erreur lors de la récupération de vos services.');
            }
        }

        fetchServices();
        fetchMyServices();
    }, []);

    const handleSubscribe = async (serviceId) => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`https://nomorewaste.fun/api/benevole/services/${serviceId}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.id,  // Inclure l'ID de l'utilisateur ici
                }),
            });
            if (!response.ok) {
                throw new Error('Erreur lors de l\'inscription au service.');
            }
            setSelectedService(services.find(service => service.id === serviceId));
            setShowModal(true);
        } catch (err) {
            console.error(err);
            setError('Erreur lors de l\'inscription au service.');
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedService(null);
    };

    const handleConfirmReservation = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`https://nomorewaste.fun/api/benevole/services/${selectedService.id}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.id,  // Inclure l'ID de l'utilisateur ici
                }),
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la confirmation de l\'inscription.');
            }
            setShowModal(false);
        } catch (err) {
            console.error(err);
            setError('Erreur lors de la confirmation de l\'inscription.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-100 py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-8">
                    Services Disponibles
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service) => (
                        <div key={service.id} className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-2">{service.type}</h2>
                            <p className="text-gray-700 mb-4">{service.description}</p>
                            <p className="text-gray-500 mb-4"><strong>Disponibilité:</strong> {service.availability}</p>
                            <button
                                onClick={() => handleSubscribe(service.id)}
                                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                            >
                                S'inscrire
                            </button>
                        </div>
                    ))}
                </div>

                <h2 className="text-3xl font-extrabold text-gray-900 text-center my-8">
                    Mes Services
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {myServices.map((service) => (
                        <div key={service.id} className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-2">{service.type}</h2>
                            <p className="text-gray-700 mb-4">{service.description}</p>
                            <p className="text-gray-500 mb-4"><strong>Disponibilité:</strong> {service.availability}</p>
                            {/* Vous pouvez ajouter des boutons pour gérer les inscriptions ici si nécessaire */}
                        </div>
                    ))}
                </div>

                {showModal && (
                    <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${showModal ? 'block' : 'hidden'}`}>
                        <div className="bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-4">Confirmer l'inscription</h2>
                            <p>Service: {selectedService?.type}</p>
                            <p><strong>Description:</strong> {selectedService?.description}</p>
                            <p><strong>Date:</strong> {selectedService?.availability}</p>

                            {error && <p className="text-red-500">{error}</p>}

                            <div className="flex justify-end mt-4">
                                <button onClick={closeModal} className="px-4 py-2 bg-gray-500 text-white rounded">Annuler</button>
                                <button
                                    onClick={handleConfirmReservation}
                                    className={`ml-2 px-4 py-2 bg-green-500 text-white rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={loading}
                                >
                                    {loading ? 'Inscription...' : 'Confirmer'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReservationService;
