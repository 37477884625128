import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const DistributionList = () => {
    const [distributions, setDistributions] = useState([]);
    const [error, setError] = useState(null);
    const [filteredDistributions, setFilteredDistributions] = useState([]);
    const [filter, setFilter] = useState({
        status: '',
        zone: '',
        month: '',
        year: ''
    });

    useEffect(() => {
        const fetchDistributions = async () => {
            try {
                const response = await fetch('https://nomorewaste.fun/api/distributions');
                if (!response.ok) {
                    throw new Error('Erreur lors du chargement des distributions');
                }
                const data = await response.json();
                setDistributions(data);
                setFilteredDistributions(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchDistributions();
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const applyFilters = () => {
        let filtered = distributions;

        if (filter.status) {
            filtered = filtered.filter(distribution => distribution.status === filter.status);
        }
        if (filter.zone) {
            filtered = filtered.filter(distribution => distribution.zone === filter.zone);
        }
        if (filter.month) {
            filtered = filtered.filter(distribution => new Date(distribution.date).getMonth() + 1 === parseInt(filter.month));
        }
        if (filter.year) {
            filtered = filtered.filter(distribution => new Date(distribution.date).getFullYear() === parseInt(filter.year));
        }

        setFilteredDistributions(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [filter]);

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://nomorewaste.fun/api/distribution/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setDistributions(distributions.filter(distribution => distribution.id !== id));
                setFilteredDistributions(filteredDistributions.filter(distribution => distribution.id !== id));
            } else {
                console.error("Erreur lors de la suppression de la distribution:", response.statusText);
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de la distribution:", error);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Gestion des Distributions</h1>
            {error ? (
                <p className="text-red-500">Erreur lors du chargement des distributions : {error}</p>
            ) : (
                <>
                    <div className="mb-4 flex space-x-4">
                        <div>
                            <label className="block text-gray-700">Statut</label>
                            <select 
                                name="status"
                                value={filter.status}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                            >
                                <option value="">Tous les statuts</option>
                                <option value="En cours">En cours</option>
                                <option value="Terminée">Terminée</option>
                                <option value="Annulée">Annulée</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-700">Département</label>
                            <input 
                                type="text" 
                                name="zone"
                                value={filter.zone}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Numéro du département"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Mois</label>
                            <input 
                                type="number" 
                                name="month"
                                value={filter.month}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Mois (ex: 1 pour Janvier)"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700">Année</label>
                            <input 
                                type="number" 
                                name="year"
                                value={filter.year}
                                onChange={handleFilterChange}
                                className="shadow border rounded py-2 px-3 text-gray-700"
                                placeholder="Année (ex: 2024)"
                            />
                        </div>
                    </div>

                    {filteredDistributions.length === 0 ? (
                        <p className="text-red-500">Aucune distribution trouvée.</p>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
                            {filteredDistributions.map((distribution) => (
                                <div key={distribution.id} className="bg-white shadow-lg rounded-lg p-4">
                                    <h2 className="text-xl font-semibold">
                                        Distribution du {new Date(distribution.date).toLocaleDateString('fr-FR')}
                                    </h2>
                                    <p className="text-sm text-gray-600">Statut: {distribution.status}</p>
                                    <p className="text-sm text-gray-600">Département: {distribution.zone}</p>
                                    <p className="text-sm text-gray-600">Lieu: {distribution.lieu}</p>
                                    <p className="text-sm text-gray-600">Capacité maximale: {distribution.capaciteMaximale}</p>
                                    <p className="text-sm text-gray-600">Nombre d'inscrits: {distribution.nombreInscrits}</p>
                                    <div className="mt-4 flex space-x-4">
                                        <Link to={`/admin/distributions/edit/${distribution.id}`} className="bg-blue-500 text-white px-4 py-2 rounded">
                                            Modifier
                                        </Link>
                                        <button 
                                            className="bg-red-500 text-white px-4 py-2 rounded"
                                            onClick={() => handleDelete(distribution.id)}
                                        >
                                            Supprimer
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}

            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"> 
                <Link to="/admin/distributions/new">Créer une nouvelle distribution</Link>
            </button>
        </div>
    );
};

export default DistributionList;
