import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './NavBar'; // Assurez-vous que le chemin est correct

function Header() {
  return (
    <header className="bg-[#E2CBAF] text-marron py-8">
      <div className="max-w-screen-xl mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src="/images/Logo.png" className="w-20" alt="No More Waste Logo" />
          <h1 className="text-3xl font-bold ml-4">No More Waste</h1>
         
        </Link>
        <Navbar /> {/* Ajout de la Navbar */}
      </div>
    </header>
  );
}

export default Header;
