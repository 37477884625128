import React from 'react';

const StatusModal = ({ isOpen, onClose, currentStatus, onSave }) => {
    const [status, setStatus] = React.useState(currentStatus);

    const handleSave = () => {
        onSave(status);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">Modifier le Statut</h2>
                <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="w-full p-2 border rounded"
                >
                    <option value="Pending">Pending</option>
                    <option value="En stock">En Stock</option>
                    <option value="Distribué">Distribué</option>
                    <option value="Annulé">Annulé</option>
                </select>
                <div className="mt-4 flex justify-end space-x-2">
                    <button
                        className="px-4 py-2 bg-gray-500 text-white rounded"
                        onClick={onClose}
                    >
                        Annuler
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        onClick={handleSave}
                    >
                        Sauvegarder
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StatusModal;
