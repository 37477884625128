import React from 'react';
import { Link } from 'react-router-dom';


function Service() {
    return (
        <div className="bg-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-8">
          Bienvenue dans notre section d'échanges de services !
        </h1>
        <p className="text-lg text-gray-700 text-center mb-12">
            La où les gens de notre communauté peuvent demander de l'aide aux autres ou proposer la leurs. Bricolage,
électricité, plomberie, gardiennage et bien d'autre !
         </p>
         <div className="text-center">
          <Link
            to="/user/service/reservation-service"
            className="inline-block px-8 py-4 bg-green-500 text-white text-lg font-semibold rounded-lg shadow hover:bg-green-600 transition-colors"
          >
            Liste de Service à réaliser 
          </Link>
        </div>
        <div className="text-center">
          <Link
            to="/user/service/create-service"
            className="inline-block px-8 py-4 bg-green-500 text-white text-lg font-semibold rounded-lg shadow hover:bg-green-600 transition-colors"
          >
            Soumettre une demande de Service
          </Link>
        </div>
      </div>
      </div>
  );
}

export default Service;