import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import pour la redirection
import Modal from 'react-modal';

// Important: Set the app element for accessibility reasons
Modal.setAppElement('#root');

function Inscription() {
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [typeUtilisateur, setTypeUtilisateur] = useState('');
  const [informationsSupplementaires, setInformationsSupplementaires] = useState('');
  const [errors, setErrors] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();  // Utilisation pour la redirection

  const openModal = (message) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation côté client
    if (!nom || !email || !motDePasse || !typeUtilisateur) {
      setErrors({
        nom: !nom ? 'Nom est requis' : '',
        email: !email ? 'Email est requis' : '',
        motDePasse: !motDePasse ? 'Mot de passe est requis' : '',
        typeUtilisateur: !typeUtilisateur ? "Type d'utilisateur est requis" : '',
      });
      openModal('Veuillez remplir tous les champs requis.');
      return;
    }

    // Préparer les données pour l'envoi
    const data = {
      nom,
      email,
      mot_de_passe: motDePasse,
      type_utilisateur: typeUtilisateur,
      informations_supplementaires: informationsSupplementaires,
    };

    // Envoi des données au backend
    try {
      const response = await fetch('https://nomorewaste.fun/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (response.ok && responseData.success) {
        // Afficher un message de succès dans la modale
        openModal('Inscription réussie !');
        
        // Rediriger vers la page d'accueil après 3 secondes
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        openModal(`Erreur d'inscription: ${responseData.message || 'Une erreur est survenue.'}`);
        setErrors({ form: responseData.message });
        console.error("Erreur d'inscription", responseData);
      }
    } catch (error) {
      console.error('Erreur de réseau', error);
      setErrors({ form: 'Erreur de réseau. Veuillez réessayer plus tard.' });
      openModal('Erreur de réseau. Veuillez réessayer plus tard.');
    }
  };

  return (
    <section className="py-20 bg-[#F0F4E3]">
      <div className="max-w-md mx-auto px-4 bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-[#B1702E] mb-8">Inscription</h2>
        {errors.form && (
          <div className="text-red-500 text-sm mb-4">
            {errors.form}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          {/* Champs du formulaire */}
          <div className="mb-6">
            <label htmlFor="nom" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Nom complet</label>
            <input
              type="text"
              id="nom"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              className={`w-full px-4 py-2 border ${errors.nom ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E] shadow-sm`}
              autoComplete="name"
            />
            {errors.nom && (
              <div className="text-red-500 text-sm mt-1">
                {errors.nom}
              </div>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full px-4 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E] shadow-sm`}
              autoComplete="email"
            />
            {errors.email && (
              <div className="text-red-500 text-sm mt-1">
                {errors.email}
              </div>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="mot_de_passe" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Mot de passe</label>
            <input
              type="password"
              id="mot_de_passe"
              value={motDePasse}
              onChange={(e) => setMotDePasse(e.target.value)}
              className={`w-full px-4 py-2 border ${errors.motDePasse ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E] shadow-sm`}
              autoComplete="new-password"
            />
            {errors.motDePasse && (
              <div className="text-red-500 text-sm mt-1">
                {errors.motDePasse}
              </div>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="type_utilisateur" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Type d'utilisateur</label>
            <select
              id="type_utilisateur"
              value={typeUtilisateur}
              onChange={(e) => setTypeUtilisateur(e.target.value)}
              className={`w-full px-4 py-2 border ${errors.typeUtilisateur ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E] shadow-sm`}
              autoComplete="off"
            >
              <option value="">Sélectionnez un type</option>
              <option value="bénévole">Benevole</option>
              <option value="commerçant">Commerçant</option>
              <option value="wastee">Wastee</option>
            </select>
            {errors.typeUtilisateur && (
              <div className="text-red-500 text-sm mt-1">
                {errors.typeUtilisateur}
              </div>
            )}
          </div>
          {typeUtilisateur === 'commerçant' && (
            <div className="mb-6">
              <label htmlFor="informations_supplementaires" className="block text-left text-[#B1702E] text-sm font-semibold mb-2">Informations supplémentaires</label>
              <textarea
                id="informations_supplementaires"
                value={informationsSupplementaires}
                onChange={(e) => setInformationsSupplementaires(e.target.value)}
                rows="4"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#B1702E] shadow-sm"
                autoComplete="off"
              ></textarea>
            </div>
          )}
          <button type="submit" className="bg-[#B1702E] text-white py-3 px-6 rounded-lg hover:bg-[#A15B1B] transition-colors">S'inscrire</button>
        </form>
      </div>

      {/* Modal for displaying messages */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Message"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content bg-[#8B4513] text-white p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Message</h2>
          <div>{modalMessage}</div>
          <button onClick={closeModal} className="mt-4 bg-white text-[#8B4513] py-2 px-4 rounded-lg">
            OK
          </button>
        </div>
      </Modal>
    </section>
  );
}

export default Inscription;
