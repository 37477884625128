import React from 'react';
import ServiceCalendar from './ServiceCalendar';

function Calendrier() {
    return (
        <div>
            <h1>Calendrier des Services</h1>
            <ServiceCalendar />
        </div>
    );
}

export default Calendrier;
