import React from 'react';

function CookingClassForm({ cookingClassData, volunteers, handleChange, handleSubmit }) {
    return (
        <form onSubmit={handleSubmit} className="space-y-6 bg-white p-8 rounded-lg shadow-md">
            <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="title">Title</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={cookingClassData.title}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="description">Description</label>
                <textarea
                    id="description"
                    name="description"
                    value={cookingClassData.description}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="startTime">Start Time</label>
                <input
                    type="datetime-local"
                    id="startTime"
                    name="startTime"
                    value={cookingClassData.startTime}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="duration">Duration (minutes)</label>
                <input
                    type="number"
                    id="duration"
                    name="duration"
                    value={cookingClassData.duration}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="maxParticipants">Max Participants</label>
                <input
                    type="number"
                    id="maxParticipants"
                    name="maxParticipants"
                    value={cookingClassData.maxParticipants}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    required
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700" htmlFor="volunteerId">Volunteer</label>
                <select
                    id="volunteerId"
                    name="volunteerId"
                    value={cookingClassData.volunteerId}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    required
                >
                    <option value="">Select a volunteer</option>
                    {volunteers.map((volunteer) => (
                        <option key={volunteer.id} value={volunteer.id}>
                            {volunteer.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-150"
                >
                    Create Cooking Class
                </button>
            </div>
        </form>
    );
}

export default CookingClassForm;
